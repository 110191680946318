<!-- integracion: borrar funcion fnRevisarPendientes-->
<template>
  <v-container>
    <v-row>
      <v-col v-for="(item, i) in sortedItemsRectificaciones" :key="i" cols="4">
        <v-card outlined>
          <v-card-title>
            <small title="Rectificación ID" class="font-weight-thin mr-1"> Rect. {{ item.id }}</small> -
            {{ item.perfil_de_usuario }} -
            {{ item.rectificacion_tipo }}</v-card-title>
          <v-card-text class="text-center">
            <!-- /**integracion */ -->
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <!-- /**integracion */ -->
            <p class="text--primary font-weight-regular text-h6">
              {{ item.complemento_nombre }}

              <br>
              <span class="font-weight-light">
                {{ item.rectificacion_comentario }}
              </span>
            </p>

            <span v-if="item.STATUS == 1" size="52" class="mt-2">
              Estado: Aún sin rectificar
              <v-icon x-small :color="item.flag_compromiso_de_pago_id ? 'orange' : 'red'">mdi-circle</v-icon></span>
            <br>
            <span v-if="item.flag_compromiso_de_pago_id"> Con compromiso de pago ID {{ item.flag_compromiso_de_pago_id
              }} <v-btn text small :to="`/detalle-gestion/${item.gestion_id}/CompromisosDePago`"> ir <v-icon
                  x-small>mdi-comment-arrow-right-outline</v-icon></v-btn></span>
            <br>

            <span v-if="item.STATUS == 3" size="52" class="mt-2">
              Aprobado
              <v-icon x-small color="green">mdi-circle</v-icon></span>
            <span v-if="item.STATUS == 5" size="52" class="mt-2">
              Rechazado
              <v-icon small color="red">mdi-window-close</v-icon></span>
            <p>{{ item.comentario }}</p>
          </v-card-text>
          <v-card-text class="text-center">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-btn v-if="item.fisicoLink" x-small color="grey" outlined class="px-4 mr-n2" elevation="1"
                  @click="fnRectifiacionFisico(item)">
                  Físico
                </v-btn>
              </v-col>
            </v-row>
            <!-- /** DOCUMENTOS DE IDENTIFICACION */ -->
            <v-row justify="center">
              <v-col>
                <v-select clearable :disabled="(item.STATUS >= 3 && item.STATUS != 4) || item.STATUS == 1
                  " v-model="item.STATUS" :items="itemsEstado" label="Estado" rounded single-line dense
                  append-icon="mdi-chevron-down" class="px-1" @change="fnCheckComplementoEstado(item, i)">
                  <template slot="selection" slot-scope="data">
                    {{ fnGetStatusText(data.item) }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ fnGetStatusText(data.item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <p class="text-h6 font-weight-regular mt-2">Revisión contrato cargado</p>

    <!-- ventana de dialogo para mostrar la fotografía del contrato físico -->
    <v-dialog v-model="dialogFisico" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Fotografía contrato parte {{ infoRectifiacion.parte }}</v-card-title>

        <v-card-text>
          <v-img aspect-ratio="1.4" contain transition="scale-transition" alt="fotografía contrato"
            :src="isBase64(infoRectifiacion.fisicoLink) ? infoRectifiacion.fisicoLink : VUE_APP_FEATHERS_SERVER + infoRectifiacion.fisicoLink" />
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small @click="
            fnDescargarContratoFotografia(
              infoRectifiacion.fisicoLink,
              `${infoRectifiacion.complemento_nombre}`
            )
            ">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogFisico = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana de dialogo para mostrar el contrato digital -->
    <v-dialog v-model="dialogDigital" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Contrato digital parte {{ infoRectifiacion.parte }}</v-card-title>
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img alt="contrato digital" class="shrink" contain :src="computedContratoImagen(infoRectifiacion.parte)"
            width="300" />
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small
            @click="fnDescargarContratoDigital(infoRectifiacion)">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogDigital = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoAprobado" max-width="90%" persistent>
      <v-card v-if="modalComplementoAprobadoData">
        <v-card-title class="headline">Aprobar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea aprobar el complemento
            <strong>{{
              modalComplementoAprobadoData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. La información de la gestión original sera reemplazada por la
            rectificación.
          </p>
          <v-row v-if="modal_original_complemento.isFile">
            <v-col>
              rechazo:
              <v-img aspect-ratio="1.4" contain transition="scale-transition"
                :src="VUE_APP_FEATHERS_SERVER + modal_original_complemento.file" />
            </v-col>
            <v-col>
              corrección:
              <v-img aspect-ratio="1.4" contain transition="scale-transition"
                :src="isBase64(modalComplementoAprobadoData.rectificacion_fotografia) ? modalComplementoAprobadoData.rectificacion_fotografia : VUE_APP_FEATHERS_SERVER + modalComplementoAprobadoData.rectificacion_fotografia" />

            </v-col>
          </v-row>
          <v-row v-if="!modal_original_complemento.isFile">
            <v-col>
              rechazo:
              <table>
                <tbody>
                  <tr>
                    <td>Nombre</td>
                    <td>{{ modal_original_complemento.nombre }}</td>
                  </tr>
                  <tr>
                    <td>Teléfono</td>
                    <td>{{ modal_original_complemento.telefono }}</td>
                  </tr>
                  <tr>
                    <td>Parentesco</td>
                    <td>{{ modal_original_complemento.parentesco }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col>
              corrección:
              <table>
                <tbody>
                  <tr>
                    <td>Nombre</td>
                    <td>
                      {{
                        modalComplementoAprobadoData.atributo_referencia_nombre
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Teléfono</td>
                    <td>
                      {{
                        modalComplementoAprobadoData.atributo_referencia_telefono
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Parentesco</td>
                    <td>
                      {{
                        modalComplementoAprobadoData.atributo_referencia_parentesco
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="fnAprobarComplemento(modalComplementoAprobadoData)">Sí, aprobar
            rectificación</v-btn>
          <v-btn color="grey" @click="cancelarAprobado">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoRechazado" max-width="500">
      <v-card v-if="modalComplementoRechazadoData">
        <v-card-title class="headline">Aprobar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea rechazar el complemento
            <strong>{{
              modalComplementoRechazadoData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento para rectificación.
          </p>
          <p>
            *. Esta rectificación desaparecerá hasta que sea corregida por la
            supervisora.
          </p>
          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Motivo del rechazo" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="fnRechazarComplemento(modalComplementoRechazadoData)"
            :disabled="!comentarioValido">Sí, enviar a rectificación</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoPendiente" max-width="500" persistent>
      <v-card v-if="modalComplementoPendienteData">
        <v-card-title class="headline">Marcar como pendiente</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea marcar como pendiente el complemento
            <strong>{{
              modalComplementoPendienteData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento como pendiente.
          </p>

          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Comentario obligatorio:" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="spacer" dark @click="fnPendienteComplemento(modalComplementoPendienteData)"
            :disabled="!comentarioValido">Sí, marcar como pendiente</v-btn>
          <v-btn color="grey" @click="cancelaPendiente()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <!-- /** */ -->
    <v-snackbar v-model="snackbar" timeout="3500" color="success" right top class="mt-14">
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Estado de complemento actualizado
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- /**LOADING BAR */ -->
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'vistaRectificaciones',
  props: ['detalleEvaluacion', 'prop_perfil'],
  data() {
    return {
      snackbar: false,
      // por pendiente de complemento
      modalComplementoPendiente: false,
      modalComplementoPendienteData: null,
      modalComplementoPendienteIndex: null,
      //
      /** */
      modalComplementoAprobado: false,
      modalComplementoAprobadoData: null,
      modalComplementoAprobadoIndex: null,
      /** */
      modal_original_complemento: null,
      /**por rechazo de complemento */
      modalComplementoRechazado: false,
      modalComplementoRechazadoData: null,
      modalComplementoRechazadoIndex: null,
      still_loading: true,
      comentario: '',
      rules: {
        required: (v) => !!v || 'El comentario es requerido',
      },
      /**integracion */
      contrato: null,
      /**integracion */
      //variables para las ventánas de dialogo que muestran las ventanas de los contratos
      dialogFisico: false,
      dialogDigital: false,

      //items temporales para las cards de el apartado de CONTRATO
      itemsRectificaciones: [],

      infoRectifiacion: {}, //variable para mostrar la parte del contrato en la ventana de diálogo cuando se da clic en los respectivos botones

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      /**
       *
       * 3: aprobado
       * 4: pendiente
       * 5: rechazado
       */
      itemsEstado: [3, 4, 5],
    };
  },
  computed: {
    sortedItemsRectificaciones() {
      // Define the desired order of statuses
      const statusOrder = [4, 2, 1, 5, 3];

      // Create a copy of the array before sorting to avoid side effects
      return [...this.itemsRectificaciones].sort((a, b) => {
        // Get the index of the status in the order array
        const indexA = statusOrder.indexOf(a.estado);
        const indexB = statusOrder.indexOf(b.estado);

        // If both statuses are in the order array, compare their indexes
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        // If only one of the statuses is in the order array, it goes first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // If neither status is in the order array, keep their original order
        return 0;
      });
    },
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    comentarioValido() {
      return !!this.comentario;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
  },
  methods: {
    isBase64(str) {

      if (str) {
        if (str.substring(0, 11) == 'data:image/') {
          return true
        }
        else
          return false
      } else {
        return false
      }

    },
    computedContratoImagen(contratoParte) {
      if (this.detalleEvaluacion.tipo_de_persona_nacionalidad == 'extranjero') {
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVExtranjera1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVExtranjera2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVExtranjera3.jpg');
          default:
            return require('@/assets/images/contrato.jpg');
        }
      } else {
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVNacional1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVNacional2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVNacional3.jpg');
          default:
            return require('@/assets/images/contrato.jpg');
        }

      }
    },
    fnGetStatusText(status) {
      if (status == null) {
        return 'sin revisar';
      }
      if (status == 3) {
        return 'Aprobado';
      }
      if (status == 4) {
        return 'Pendiente';
      }
      if (status == 5) {
        return 'Rechazado';
      }
    },
    ...mapActions('Pendientes', {
      findPendientes: 'find',
      createPendientes: 'create',
      getPendientes: 'get',
      patchPendientes: 'patch',
    }),
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      patchGestiones: 'patch',
    }),
    ...mapActions('Contratos', {
      findContratos: 'find',
      getContratos: 'get',
      patchContratos: 'patch',
    }),
    ...mapActions('DocumentosDeIdentificacion', {
      findDocumentosDeIdentificacion: 'find',
      createDocumentosDeIdentificacion: 'create',
      getDocumentosDeIdentificacion: 'get',
      patchDocumentosDeIdentificacion: 'patch',
    }),
    ...mapActions('FotografiasDeCasa', {
      findFotografiasDeCasa: 'find',
      createFotografiasDeCasa: 'create',
      getFotografiasDeCasa: 'get',
      patchFotografiasDeCasa: 'patch',
    }),
    ...mapActions('Referencias', {
      findReferencias: 'find',
      createReferencias: 'create',
      getReferencias: 'get',
      patchReferencias: 'patch',
    }),
    ...mapActions('Recibos', {
      findRecibos: 'find',
      createRecibos: 'create',
      getRecibos: 'get',
      patchRecibos: 'patch',
    }),
    ...mapActions('RecibosComerciales', {
      findRecibosComerciales: 'find',
      getRecibosComerciales: 'get',
      patchRecibosComerciales: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      findRectificaciones: 'find',
      createRectificaciones: 'create',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),

    //
    async main() {
      /**integracion */

      console.log('hay nuevo detalleEvaluacion - contrato side');

      if (this.detalleEvaluacion) {
        /**obtener informacion de CONTRATO
         * Y SIGNARLO A itemsRectificaciones
         */
        await this.findRectificaciones({
          query: {
            perfil_de_usuario: this.prop_perfil,
            gestion_id: this.detalleEvaluacion.id,
            $limit: 100,
            $sort: {
              id: 1,
            },
          },
        }).then((r) => {
          console.log('rectificaciones encontradas:', r);

          for (let index = 0; index < r.data.length; index++) {
            r.data[index].estado = r.data[index].STATUS;
            r.data[index].original_estado = r.data[index].STATUS;
            r.data[index].fisicoLink = r.data[index].rectificacion_fotografia;
          }

          this.itemsRectificaciones = r.data;
          console.log('las rectificaciones', this.itemsRectificaciones);
          /** */
          let vm = this;
          setTimeout(() => {
            vm.still_loading = false;
          }, 500);
        });
      }
      /**integracion */
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },

    /*** */
    async fnCheckComplementoEstado(complemento, index) {

      console.log('actualizando estado dew complemento', complemento, index);

      /** ACTUALIZACION DIRECTA A LA BASE DE DATOS POR ESTADOS APROBADO O PENDIENTE */
      /** obtener informacion original */
      switch (complemento.rectificacion_tipo) {
        case 'contrato':
          await this.getContratos(complemento.complemento_id).then((r) => {
            /** */
            if (complemento.complemento_nombre == 'Contrato parte uno') {
              let original_Body = {
                isFile: true,
                file: r.contrato_parte_uno_fotografia,
              };
              this.modal_original_complemento = original_Body;
            } else if (complemento.complemento_nombre == 'Contrato parte dos') {
              let original_Body = {
                isFile: true,
                file: r.contrato_parte_dos_fotografia,
              };
              this.modal_original_complemento = original_Body;
            } else if (
              complemento.complemento_nombre == 'Contrato parte tres'
            ) {
              let original_Body = {
                isFile: true,
                file: r.contrato_parte_tres_fotografia,
              };
              this.modal_original_complemento = original_Body;
            } else {
              let original_Body = {
                isFile: false,
                file: null,
              };
              this.modal_original_complemento = original_Body;
            }
            /** */
          });
          break;
        case 'documento de identificación':
          await this.getDocumentosDeIdentificacion(
            complemento.complemento_id
          ).then((r) => {
            /** */
            if (
              complemento.complemento_nombre ==
              'Documento de identificacion frontal'
            ) {
              let original_Body = {
                isFile: true,
                file: r.documento_de_identificacion_frontal,
              };
              this.modal_original_complemento = original_Body;
            } else if (
              complemento.complemento_nombre ==
              'Documento de identificacion anverso'
              ||
              complemento.complemento_nombre ==
              'Documento de identificacion reverso'
            ) {
              let original_Body = {
                isFile: true,
                file: r.documento_de_identificacion_anverso,
              };
              this.modal_original_complemento = original_Body;
            } else if (
              complemento.complemento_nombre ==
              'Documento de identificacion con persona'
            ) {
              let original_Body = {
                isFile: true,
                file: r.documento_de_identificacion_con_persona,
              };
              this.modal_original_complemento = original_Body;
            } else {
              let original_Body = {
                isFile: false,
                file: null,
              };
              this.modal_original_complemento = original_Body;
            }
            /** */
          });
          break;
        case 'recibo de servcio':
          await this.getRecibos(complemento.complemento_id).then((r) => {
            let original_Body = {
              isFile: true,
              file: r.fotografia,
            };
            this.modal_original_complemento = original_Body;
            console.log('se actualizado complemento de tipo recibo de servicio', this.modal_original_complemento);
          });
          break;
        case 'recibo comercial':
          await this.getRecibosComerciales(complemento.complemento_id).then((r) => {
            let original_Body = {
              isFile: true,
              file: r.fotografia,
            };
            this.modal_original_complemento = original_Body;
          });
          break;
        case 'fotografía de casa':
          await this.getFotografiasDeCasa(complemento.complemento_id).then(
            (r) => {
              let original_Body = {
                isFile: true,
                file: r.fotografia,
              };
              this.modal_original_complemento = original_Body;
            }
          );
          break;
        case 'referencias':
          await this.getReferencias(complemento.complemento_id).then((r) => {
            let original_Body = {
              isFile: false,
              nombre: r.nombre,
              telefono: r.telefono,
              parentesco: r.parentesco,
            };
            this.modal_original_complemento = original_Body;
          });
          break;
        default:
          throw new Error('Tipo no reconocido');
      }
      /** fin obtener informacion original */

      /**rectificacionii iaprobar */
      if (complemento.STATUS == 3) {
        this.modalComplementoAprobado = true;
        this.modalComplementoAprobadoData = complemento;
        this.modalComplementoAprobadoIndex = index;
      }
      /** rectificacion pendiente */
      if (complemento.STATUS == 4) {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoPendiente = true;
        this.modalComplementoPendienteData = complemento;
        this.modalComplementoPendienteIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }

      if (complemento.STATUS == 5) {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoRechazado = true;
        this.modalComplementoRechazadoData = complemento;
        this.modalComplementoRechazadoIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }

      console.log(index);
    },
    //
    fn_check_if_pendiente_debe_actualizarse(complemento) {
      console.log(complemento);
      this.findPendientes({
        query: {
          STATUS: 1,
          gestion_id: this.detalleEvaluacion.id,
          complemento_id: complemento.complemento_id,
          complemento_nombre: complemento.complemento_nombre
        }
      }).then(result => {
        // Verifica si existe algún pendiente
        if (result.data && result.data.length > 0) {
          const pendiente = result.data[0]; // Supongamos que solo necesitas el primero

          // Realiza el patch con el id del pendiente encontrado
          this.patchPendientes([pendiente.id, { STATUS: 2 }]);
        } else {
          console.error('No se encontró ningún pendiente, al aprobar el complemento');
        }
      }).catch(error => {
        console.error('Error al buscar el pendiente:', error);
      });
    },
    //
    async fnRechazarComplemento(complemento) {
      /**si tiene pendiente, se actualiza a aprobado */
      this.fn_check_if_pendiente_debe_actualizarse(complemento);
      /**si tiene pendiente, se actualiza a aprobado, fin */

      await this.createRectificaciones({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: this.prop_perfil,
        rectificacion_tipo: complemento.rectificacion_tipo,
        complemento_id: complemento.complemento_id,
        complemento_nombre: complemento.complemento_nombre,
        rectificacion_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        status_tbl_attribute: complemento.status_tbl_attribute,
        pais: this.detalleEvaluacion.pais,
        flag_tiene_asigando_compromiso_de_pago: complemento.flag_tiene_asigando_compromiso_de_pago,
        flag_compromiso_de_pago_id: complemento.flag_compromiso_de_pago_id
        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(async () => {
        /** actualizar estado de rectificaion rechazada */
        await this.patchRectificaciones([
          this.modalComplementoRechazadoData.id,
          { STATUS: 5 },
        ]).then(async () => {
          /**actualizacion de complemento */
          await this.updateComplementoEstadoARechazadoOPendiente(this.modalComplementoRechazadoData, 'Rechazado')
          //
          this.modalComplementoRechazado = false;
          this.modalComplementoRechazadoData = null;
          this.comentario = null;
          this.$notify({
            title: 'Revision rechazada, genera nueva rectifiacion',
            text: 'La revisión se ha marcado como completada.',
            color: '#2E7D32',
            timeout: 3000,
          });
        });
      });
    },
    async fnAprobarComplemento(complemento) {

      /**si tiene pendiente, se actualiza a aprobado */
      this.fn_check_if_pendiente_debe_actualizarse(complemento);
      /**si tiene pendiente, se actualiza a aprobado, fin */


      await this.patchRectificaciones([
        complemento.id,
        { STATUS: 3 },
      ]).then(async () => {

        /***actualizar complemento como aprobado */
        await this.updateComplementoEstadoAprobado(complemento, 'Aprobado')

      });

      //
    },
    async fnPendienteComplemento(complemento) {

      /**actualiza la rectificacion */
      this.patchRectificaciones([complemento.id, { STATUS: 4 }])
        .then(async () => {
        })
        .catch((e) => {
          this.$notify({
            title: 'ERROR',
            text: e,
            color: '#E53935',
            timeout: 3000,
          });
        });


      /**Crear pendiente */
      await this.createPendientes({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: this.prop_perfil,
        pendiente_tipo: complemento.rectificacion_tipo,
        complemento_id: complemento.complemento_id,
        status_tbl_attribute: complemento.status_tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        pendiente_comentario: this.comentario,
        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(async () => {

        /**Actualiza complemento */
        await this.updateComplementoEstadoARechazadoOPendiente(complemento, 'Pendiente');
        /** */
        this.snackbar = true;
        this.modalComplementoPendiente = false;
        this.modalComplementoPendienteData = null;
        this.comentario = null;
      });
    },
    async updateComplementoEstadoAprobado(complemento, estado) {
      /**contrato */
      /**CONTRATO PARTE 1 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte uno'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_uno_fotografia:
              complemento.rectificacion_fotografia,
            contrato_parte_uno_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 1, FIN */
      /**CONTRATO PARTE 2 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte dos'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_dos_fotografia:
              complemento.rectificacion_fotografia,
            contrato_parte_dos_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 2, FIN */

      /**CONTRATO PARTE 3 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte tres'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_tres_fotografia:
              complemento.rectificacion_fotografia,
            contrato_parte_tres_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 3, FIN */

      /**documento de identificacion
       *
       *
       */
      /*documento_de_identificacion_frontal */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion frontal'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**fotografia */
            documento_de_identificacion_frontal:
              complemento.rectificacion_fotografia,
            /**estado */
            documento_de_identificacion_frontal_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_frontal, FIN */
      /*documento_de_identificacion_anverso */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion anverso'
        ||
        complemento.complemento_nombre ==
        'Documento de identificacion reverso'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**fotografia */
            documento_de_identificacion_anverso:
              complemento.rectificacion_fotografia,
            /**estado */
            documento_de_identificacion_anverso_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_anverso, FIN */
      /*documento_de_identificacion_con_persona */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion con persona'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**fotografia */
            documento_de_identificacion_con_persona:
              complemento.rectificacion_fotografia,
            /**estado */
            documento_de_identificacion_con_persona_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_con_persona, FIN */

      /*recibo des ervicio */
      if (
        complemento.complemento_nombre ==
        'Recibo de servicio'
      ) {
        await this.patchRecibos([
          complemento.complemento_id,
          {
            /**recibo des ervicio */
            fotografia:
              complemento.rectificacion_fotografia,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo des ervicio, FIN */

      /*recibo des ervicio direccion de entrega */
      if (
        complemento.complemento_nombre ==
        'Recibo de servicio dirección de entrega'
      ) {
        await this.patchRecibos([
          complemento.complemento_id,
          {
            /**recibo des ervicio */
            fotografia:
              complemento.rectificacion_fotografia,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo des ervicio direccion de entrega FIN */

      /*recibo comercial */
      if (
        complemento.complemento_nombre ==
        'Recibo comercial 1' || complemento.complemento_nombre ==
        'Recibo comercial 2'
      ) {
        await this.patchRecibosComerciales([
          complemento.complemento_id,
          {
            /**recibo des ervicio */
            fotografia:
              complemento.rectificacion_fotografia,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo comercial, FIN */

      /*fotografia de casa */
      if (
        complemento.complemento_nombre ==
        'Fotografía de casa'
      ) {
        await this.patchFotografiasDeCasa([
          complemento.complemento_id,
          {
            /**fotografia de casa */
            fotografia:
              complemento.rectificacion_fotografia,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*fotografia de casa, FIN */

      /*referencia */
      if (
        complemento.rectificacion_tipo == 'referencias'
      ) {
        await this.patchReferencias([
          complemento.complemento_id,
          {
            /**referencia */
            nombre:
              complemento.atributo_referencia_nombre,
            telefono:
              complemento.atributo_referencia_telefono,
            parentesco:
              complemento.atributo_referencia_parentesco,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: 'Revision aprobada',
              text: 'La rectificación se ha marcado como aprobada.',
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*referencia, FIN */

      /** */
      this.modalComplementoAprobado = false;
      this.modalComplementoAprobadoData = null;
      this.modalComplementoAprobadoIndex = null;
    },
    async updateComplementoEstadoARechazadoOPendiente(complemento, estado) {
      /**contrato */
      /**CONTRATO PARTE 1 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte uno'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_uno_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 1, FIN */
      /**CONTRATO PARTE 2 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte dos'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_dos_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 2, FIN */

      /**CONTRATO PARTE 3 */
      if (
        complemento.complemento_nombre ==
        'Contrato parte tres'
      ) {
        await this.patchContratos([
          complemento.complemento_id,
          {
            contrato_parte_tres_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /**CONTRATO PARTE 3, FIN */

      /**documento de identificacion
       *
       *
       */
      /*documento_de_identificacion_frontal */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion frontal'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**estado */
            documento_de_identificacion_frontal_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_frontal, FIN */
      /*documento_de_identificacion_anverso */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion anverso'
        ||
        complemento.complemento_nombre ==
        'Documento de identificacion reverso'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**estado */
            documento_de_identificacion_anverso_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_anverso, FIN */
      /*documento_de_identificacion_con_persona */
      if (
        complemento.complemento_nombre ==
        'Documento de identificacion con persona'
      ) {
        await this.patchDocumentosDeIdentificacion([
          complemento.complemento_id,
          {
            /**estado */
            documento_de_identificacion_con_persona_estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*documento_de_identificacion_con_persona, FIN */

      /*recibo des ervicio */
      if (
        complemento.complemento_nombre ==
        'Recibo de servicio'
      ) {
        await this.patchRecibos([
          complemento.complemento_id,
          {
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo des ervicio, FIN */

      /*recibo des ervicio direccion de entrega */
      if (
        complemento.complemento_nombre ==
        'Recibo de servicio dirección de entrega'
      ) {
        await this.patchRecibos([
          complemento.complemento_id,
          {
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo des ervicio direccion de entrega FIN */

      /*recibo comercial */
      if (
        complemento.complemento_nombre ==
        'Recibo comercial 1' || complemento.complemento_nombre ==
        'Recibo comercial 2'
      ) {
        await this.patchRecibosComerciales([
          complemento.complemento_id,
          {
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*recibo comercial, FIN */

      /*fotografia de casa */
      if (
        complemento.complemento_nombre ==
        'Fotografía de casa'
      ) {
        await this.patchFotografiasDeCasa([
          complemento.complemento_id,
          {
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*fotografia de casa, FIN */

      /*referencia */
      if (
        complemento.rectificacion_tipo == 'referencias'
      ) {
        await this.patchReferencias([
          complemento.complemento_id,
          {
            /**referencia */
            nombre:
              complemento.atributo_referencia_nombre,
            telefono:
              complemento.atributo_referencia_telefono,
            parentesco:
              complemento.atributo_referencia_parentesco,
            /**estado */
            estado: estado,
          },
        ])
          .then(() => {
            this.$notify({
              title: `Revision ${estado}`,
              text: `La rectificación se ha marcado como ${estado}.`,
              color: '#2E7D32',
              timeout: 3000,
            });
          })
          .catch((e) => {
            this.$notify({
              title: 'Error',
              text: e,
              color: '#EF5350',
              timeout: 3000,
            });
          });
      }
      /*referencia, FIN */
    },
    cancelar() {
      // Busca el elemento con el id que coincide con modalComplementoRechazadoData.id
      const item = this.itemsRectificaciones.find(
        item => item.id === this.modalComplementoRechazadoData.id
      );

      if (item) {
        item.STATUS = this.modalComplementoRechazadoData.original_estado;
      }

      this.modalComplementoRechazado = false;
      this.modalComplementoRechazadoData = null;
      this.comentario = null;
    },

    cancelarAprobado() {
      // Busca el elemento con el id que coincide con modalComplementoAprobadoData.id
      const item = this.itemsRectificaciones.find(
        item => item.id === this.modalComplementoAprobadoData.id
      );

      if (item) {
        console.log('cancelando antes: ', item.STATUS);
        item.STATUS = this.modalComplementoAprobadoData.original_estado;
        console.log('cancelando después: ', item.STATUS);
      }

      this.modalComplementoAprobado = false;
      this.modalComplementoAprobadoData = null;
    },

    cancelaPendiente() {
      // Busca el elemento con el id que coincide con modalComplementoPendienteData.id
      const item = this.itemsRectificaciones.find(
        item => item.id === this.modalComplementoPendienteData.id
      );

      if (item) {
        console.log('cancelando antes: ', item.STATUS);
        item.STATUS = this.modalComplementoPendienteData.original_estado;
        console.log('cancelando después: ', item.STATUS);
      }

      this.modalComplementoPendiente = false;
      this.modalComplementoPendienteData = null;
    }
    ,
    //-----funciones cuando se da clic en los botones de FÍSICO - DIGITAL en las cards del apartado de CONTRATO

    //clic en botón FÍSICO
    fnRectifiacionFisico(item) {
      this.infoRectifiacion = Object.assign(item);
      console.log('se dio clic en el botón', this.infoRectifiacion); // comprobando que si se da clic sobre items diferentes dependiendo de en que card esté el botón
      this.dialogFisico = true;
    },

    //clic en botón DIGITAL
    fnContratoDigital(item) {
      this.infoRectifiacion = Object.assign(item);
      console.log('se dio clic en el botón', this.infoRectifiacion);
      this.dialogDigital = true;
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en las respectivas ventanas de dialogo, fotografía de contrato y contrato digital
    /**implementacion */
    async fnDescargarContratoFotografia(ung_url, tipo) {
      if (this.isBase64(ung_url)) {
        // Si es base64, crear un enlace de descarga directamente
        const a = document.createElement('a');
        a.href = ung_url;
        a.download = `image_${tipo}.jpg`;
        a.click();
      } else {
        // Si es una URL física, proceder con el fetch y la descarga
        const response = await fetch(this.VUE_APP_FEATHERS_SERVER + ung_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `image_${tipo}.jpg`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
    /**implementacion */
    fnDescargarContratoDigital(data) {
      console.log('descargando digital...', data.digitalLink);
      console.log(this.infoRectifiacion.digitalLink);
    },
  },
  watch: {},

  //para verificar si se tiene algun pendiente (únicamente para pruebas)
  mounted() {
    console.log('carga de rectifiacciones', this.prop_perfil)
    let vm = this;
    setTimeout(() => {
      vm.main();
    }, 500);
  },
};
</script>

<style scoped>
.v-select .v-input--dense .v-select__selection--comma {
  margin: 0px 0px 0px 20px;
}
</style>