<template>
  <v-container>
    <v-row>
      <v-col v-for="(item, i) in itemsRefCard" :key="i" cols="4">
        <v-card outlined min-height="220" v-if="
          !router_path_filtro ||
          (router_path_filtro && router_path_filtro == item.original_estado)
        ">

          <v-card-text class="text-center">
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <p class="text--primary font-weight-regular text-h6">
              {{ item.title }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-row justify="center" class="mt-n7" no-gutters>
              <v-col cols="12" md="12" xl="12" class="mb-2">
                <v-select clearable :disabled="item.estado == 'Rechazado' || flag_gestion_terminada"
                  v-model="item.estado" :items="itemsEstado" label="Estado" rounded single-line dense
                  append-icon="mdi-chevron-down" class="px-1" @change="fnActualizarEstadoComplemento(item, i)">
                </v-select>
              </v-col>
              <v-col cols="12">
                <div class="px-2 mb-2 mt-n5">
                  <table>
                    <tr>
                      <td class="text--secondary font-weight-bold text-body-2">
                        Nombre
                      </td>
                      <td class="text--secondary font-weight-regular text-body-2" align="right">
                        {{ item.nombre }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text--secondary font-weight-bold text-body-2">
                        Teléfono
                      </td>
                      <td class="text--secondary font-weight-regular text-body-2" align="right">
                        {{ item.telefono }}
                      </td>
                    </tr>

                    <tr v-if="i != 0">
                      <td class="text--secondary font-weight-bold text-body-2">
                        Parentesco
                      </td>
                      <td class="text--secondary font-weight-regular text-body-2" align="right">
                        {{ item.parentesco }}
                      </td>
                    </tr>
                  </table>
                </div>
              </v-col>



            </v-row>
          </v-card-text>

          <v-card-actions v-if="item.ultima_actualizacion_usuario">
            <v-row no-gutters>
              <v-col>
                <span class="font-weight-regular text-caption">Ultima modificacion: {{
                  fnGetDate(item.ultima_actualizacion_fecha) }}<br>
                  Por: {{ item.ultima_actualizacion_usuario }}</span>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoRechazado" max-width="500" persistent>
      <v-card v-if="modalComplementoRechazadoData">
        <v-card-title class="headline">Rechazar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea rechazar el complemento
            <strong>{{
              modalComplementoRechazadoData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento para rectificación.
          </p>
          <p>
            *. Esta rectificación desaparecerá hasta que sea corregida por la
            supervisora.
          </p>
          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Motivo del rechazo" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="rechazarComplemento(modalComplementoRechazadoData)"
            :disabled="!comentarioValido">Sí, enviar a rectificación</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoPendiente" max-width="500" persistent>
      <v-card v-if="modalComplementoPendienteData">
        <v-card-title class="headline">Marcar como pendiente</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea marcar como pendiente el complemento
            <strong>{{
              modalComplementoPendienteData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento como pendiente.
          </p>

          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Comentario obligatorio:" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="spacer" dark @click="pendienteComplemento(modalComplementoPendienteData)"
            :disabled="!comentarioValido">Sí, marcar como pendiente</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-snackbar v-model="snackbar" timeout="3500" color="success" right top class="mt-14">
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Estado de complemento actualizado
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- /**LOADING BAR */ -->
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'vistaReferencias',
  props: ['detalleEvaluacion'],
  data() {
    return {
      snackbar: false,
      // por pendiente de complemento
      modalComplementoPendiente: false,
      modalComplementoPendienteData: null,
      modalComplementoPendienteIndex: null,
      /**por rechazo de complemento */
      modalComplementoRechazado: false,
      modalComplementoRechazadoData: null,
      modalComplementoRechazadoIndex: null,
      comentario: '',
      rules: {
        required: (v) => !!v || 'El comentario es requerido',
      },
      //
      referencia: null,
      still_loading: true,
      //items temporales con la información para las cards del apartado de REFERENCIAS
      itemsRefCard: [
        {
          id: null,
          API: 'patchReferencias',
          tbl_attribute: 'estado',
          complemento_nombre: 'Titular',
          title: 'Titular',
          nombre: 'Luisa López',
          telefono: '+5026378541',
          estado: null,
          original_estado: null,
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          API: 'patchReferencias',
          tbl_attribute: 'estado',
          complemento_nombre: 'Familiar #1',
          title: 'Familiar #1',
          nombre: 'María López',
          telefono: '+5026345541',
          parentesco: 'Hermana',
          estado: null,
          original_estado: null,
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          API: 'patchReferencias',
          tbl_attribute: 'estado',
          complemento_nombre: 'Familiar #2',
          title: 'Familiar #2',
          nombre: 'María López',
          telefono: '+5026345541',
          parentesco: 'Hermana',
          estado: null,
          original_estado: null,
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          API: 'patchReferencias',
          tbl_attribute: 'estado',
          complemento_nombre: 'Personal #1',
          title: 'Personal #1',
          nombre: 'María López',
          telefono: '+5026345541',
          parentesco: 'Hermana',
          estado: null,
          original_estado: null,
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          API: 'patchReferencias',
          tbl_attribute: 'estado',
          complemento_nombre: 'Personal #2',
          title: 'Personal #2',
          nombre: 'María López',
          telefono: '+5026345541',
          parentesco: 'Hermana',
          estado: null,
          original_estado: null,
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
      ],

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      itemsEstado: ['Aprobado', 'Rechazado', 'Pendiente'],
    };
  },
  methods: {
    ...mapActions('Referencias', {
      createReferencias: 'create',
      getReferencias: 'get',
      patchReferencias: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      createRectificaciones: 'create',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('Pendientes', {
      findPendientes: 'find',
      createPendientes: 'create',
      getPendientes: 'get',
      patchPendientes: 'patch',
    }),
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    /*** */
    fnActualizarEstadoComplemento(complemento, index) {
      /** ACTUALIZACION DIRECTA A LA BASE DE DATOS POR ESTADOS APROBADO O PENDIENTE */
      if (complemento.estado == 'Aprobado') {
        const payload = {
          [complemento.tbl_attribute]: complemento.estado,
        };

        /** */
        let apiFunction = this.patchReferencias;

        /** */

        apiFunction([complemento.id, payload]).then(() => {
          this.snackbar = true;
        });

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento)
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if (complemento.estado == 'Rechazado') {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoRechazado = true;
        this.modalComplementoRechazadoData = complemento;
        this.modalComplementoRechazadoIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento)
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if (complemento.estado == 'Pendiente') {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoPendiente = true;
        this.modalComplementoPendienteData = complemento;
        this.modalComplementoPendienteIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }
    },
    async rechazarComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };
      /** */
      let apiFunction = this.patchReferencias;

      /** */

      await apiFunction([complemento.id, payload]).then(async (r) => {
        console.log('contrato actualizado', r);
      });

      /** */
      let rectificacionTipo = 'referencias';

      /** */

      await this.createRectificaciones({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'deudor',
        rectificacion_tipo: rectificacionTipo,
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        rectificacion_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoRechazado = false;
        this.modalComplementoRechazadoData = null;
        this.comentario = null;
      });
    },
    async pendienteComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };
      /** */
      let apiFunction = this.patchReferencias;
      /** */

      await apiFunction([complemento.id, payload]).then(async (r) => {
        console.log('complemento actualizado', r);
      });

      await this.createPendientes({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'deudor',
        pendiente_tipo: 'referencias',
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        pendiente_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // pendiente_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoPendiente = false;
        this.modalComplementoPendienteData = null;
        this.comentario = null;
      });
    },
    cancelar() {
      /**
       * reset estado a default value
       */
      if (this.modalComplementoRechazadoData) {
        this.itemsRefCard[this.modalComplementoRechazadoIndex].estado =
          this.modalComplementoRechazadoData.original_estado;
      }

      if (this.modalComplementoPendienteData) {
        this.itemsRefCard[this.modalComplementoPendienteIndex].estado =
          this.modalComplementoPendienteData.original_estado;
      }
      /** */
      this.modalComplementoRechazado = false;
      this.modalComplementoRechazadoData = null;
      this.modalComplementoRechazadoIndex = null;
      /** */
      this.modalComplementoPendiente = false;
      this.modalComplementoPendienteData = null;
      this.modalComplementoPendienteIndex = null;
      /** */
      this.comentario = null;
    },
    //
    async main() {
      /**conulsta datos en base de datos */
      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.titular_referencia_id
      ) {
        await this.getReferencias(
          this.detalleEvaluacion.titular_referencia_id
        ).then((r) => {
          this.itemsRefCard[0].id = r.id;
          this.itemsRefCard[0].nombre = r.nombre;
          this.itemsRefCard[0].telefono = r.telefono;
          this.itemsRefCard[0].parentesco = r.parentesco;
          this.itemsRefCard[0].estado = r.estado;
          this.itemsRefCard[0].original_estado = r.estado;
          this.itemsRefCard[0].ultima_actualizacion_usuario = r.ultima_modificacion_por_usuario_nombre;
          this.itemsRefCard[0].ultima_actualizacion_fecha = r.updatedAt;
        });
      }
      /************* */
      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.familiar_1_referencia_id
      ) {
        await this.getReferencias(
          this.detalleEvaluacion.familiar_1_referencia_id
        ).then((r) => {
          this.itemsRefCard[1].id = r.id;
          this.itemsRefCard[1].nombre = r.nombre;
          this.itemsRefCard[1].telefono = r.telefono;
          this.itemsRefCard[1].parentesco = r.parentesco;
          this.itemsRefCard[1].estado = r.estado;
          this.itemsRefCard[1].original_estado = r.estado;
          this.itemsRefCard[1].ultima_actualizacion_usuario = r.ultima_modificacion_por_usuario_nombre;
          this.itemsRefCard[1].ultima_actualizacion_fecha = r.updatedAt;
        });
      }
      /************* */
      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.familiar_2_referencia_id
      ) {
        await this.getReferencias(
          this.detalleEvaluacion.familiar_2_referencia_id
        ).then((r) => {
          this.itemsRefCard[2].id = r.id;
          this.itemsRefCard[2].nombre = r.nombre;
          this.itemsRefCard[2].telefono = r.telefono;
          this.itemsRefCard[2].parentesco = r.parentesco;
          this.itemsRefCard[2].estado = r.estado;
          this.itemsRefCard[2].original_estado = r.estado;
          this.itemsRefCard[2].ultima_actualizacion_usuario = r.ultima_modificacion_por_usuario_nombre;
          this.itemsRefCard[2].ultima_actualizacion_fecha = r.updatedAt;
        });
      }
      /************* */

      /************* */
      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.personal_1_referencia_id
      ) {
        await this.getReferencias(
          this.detalleEvaluacion.personal_1_referencia_id
        ).then((r) => {
          this.itemsRefCard[3].id = r.id;
          this.itemsRefCard[3].nombre = r.nombre;
          this.itemsRefCard[3].telefono = r.telefono;
          this.itemsRefCard[3].parentesco = r.parentesco;
          this.itemsRefCard[3].estado = r.estado;
          this.itemsRefCard[3].original_estado = r.estado;
          this.itemsRefCard[3].ultima_actualizacion_usuario = r.ultima_modificacion_por_usuario_nombre;
          this.itemsRefCard[3].ultima_actualizacion_fecha = r.updatedAt;
        });
      }
      /************* */
      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.personal_2_referencia_id
      ) {
        await this.getReferencias(
          this.detalleEvaluacion.personal_2_referencia_id
        ).then((r) => {
          this.itemsRefCard[4].id = r.id;
          this.itemsRefCard[4].nombre = r.nombre;
          this.itemsRefCard[4].telefono = r.telefono;
          this.itemsRefCard[4].parentesco = r.parentesco;
          this.itemsRefCard[4].estado = r.estado;
          this.itemsRefCard[4].original_estado = r.estado;
          this.itemsRefCard[4].ultima_actualizacion_usuario = r.ultima_modificacion_por_usuario_nombre;
          this.itemsRefCard[4].ultima_actualizacion_fecha = r.updatedAt;
        });
      }
      /************* */
      /**conulsta datos en base de datos, fin */

      let vm = this;
      await setTimeout(() => {
        vm.still_loading = false;
      }, 200);
    },
    //
    fn_check_if_pendiente_debe_actualizarse(complemento) {
      console.log(complemento);
      this.findPendientes({
        query: {
          STATUS: 1,
          gestion_id: this.detalleEvaluacion.id,
          complemento_id: complemento.id
        }
      }).then(result => {
        // Verifica si existe algún pendiente
        if (result.data && result.data.length > 0) {
          const pendiente = result.data[0]; // Supongamos que solo necesitas el primero

          // Realiza el patch con el id del pendiente encontrado
          this.patchPendientes([pendiente.id, { STATUS: 2 }]);
        } else {
          console.error('No se encontró ningún pendiente, al aprobar el complemento');
        }
      }).catch(error => {
        console.error('Error al buscar el pendiente:', error);
      });
    },
    //
  },
  computed: {
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    },
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    comentarioValido() {
      return !!this.comentario;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
  },
  mounted() {
    let vm = this;
    setTimeout(() => {
      vm.main();
    }, 200);
    /**actualizar datos al actualizar o crear compleentos de gestion */
    const {
      Contratos,
      DocumentosDeIdentificacion,
      Recibos,
      FotografiasDeCasa,
      Referencias,
      RecibosComerciales
    } = this.$FeathersVuex.api;
    Contratos.on('patched', () => {
      this.main();
    });
    DocumentosDeIdentificacion.on('patched', () => {
      this.main();
    });
    Recibos.on('patched', () => {
      this.main();
    });
    FotografiasDeCasa.on('patched', () => {
      this.main();
    });
    Referencias.on('patched', () => {
      this.main();
    });
    RecibosComerciales.on('patched', () => {
      this.main();
    });
    /**actualizar datos al actualizar o crear compleentos de gestion, fin */
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 3px;
}
</style>
