<template>
    <div>       
        <p class="text-body-2 text--secondary mt-2">
            Nombre de la Persona:: {{ equifax_json.candidateInfo.nombre }}
            <br>
            DUI: {{ detalleEvaluacion.dpi }}
            <br>
            Fecha de nacimiento: {{ descomponerFecha(equifax_json.candidateInfo.fechaNacimiento) }}
            <br>
            Periodo: {{ equifax_json.infoCrediticia.periodo[0].periodoActual }}
            <br>
            Reporte: 
                <v-btn
                x-small
                    :href="VUE_APP_FEATHERS_SERVER + reporteURL"
                    target="_blank"
                    download
                    color="primary"
                    class="ma-2"
                    >
                    <v-icon small left>mdi-download</v-icon>
                    Descargar Reporte
                </v-btn>
            </p>
        
        <h2 class="mt-2 mb-3">Scores</h2>
        <v-data-table dense :headers="calculatesHeaders" :items="[equifax_json.calculates]" class="elevation-1"
            :search="calculatesSearch" :custom-filter="customFilter" :hide-default-footer="true">
            <template v-slot:top>
                <v-text-field v-model="calculatesSearch" label="Buscar en Calculates" class="mx-4"></v-text-field>
            </template>
        </v-data-table>

        <h2 class="mt-2 mb-3">Score Actual</h2>
        <v-data-table :items-per-page="equifax_json_historico.length" dense :headers="scoreActualHeaders" :items="equifax_json_actual" class="elevation-1"
            :search="scoreActualSearch" :custom-filter="customFilter" :hide-default-footer="true">
            <template v-slot:top>
                <v-text-field v-model="scoreActualSearch" label="Buscar en Score Actual" class="mx-4"></v-text-field>
            </template>
        </v-data-table>

        <h2 id="equifax_historico_titulo" class="mt-2 mb-3">Score Histórico</h2>
        <v-data-table  id="equifax_historico_tabla" :items-per-page="equifax_json_historico.length" dense :headers="scoreHistoricoHeaders"
            :items="equifax_json_historico" class="elevation-1" :search="scoreHistoricoSearch"
            :custom-filter="customFilter" :hide-default-footer="true">
            <template v-slot:top>
                <v-text-field v-model="scoreHistoricoSearch" label="Buscar en Score Histórico"
                    class="mx-4"></v-text-field>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ['reporteURL','detalleEvaluacion', 'equifax_json_actual', 'equifax_json_historico', 'equifax_json'],
    data() {
        return {
            // Filtros de búsqueda
            calculatesSearch: "",
            scoreActualSearch: "",
            scoreHistoricoSearch: "",

           

            // Definición de encabezados para tablas
            calculatesHeaders: [
                { text: "be_score_prom", value: "be_score_prom" },
                { text: "be_score", value: "be_score" },
            ],
            scoreActualHeaders: [
                { text: "Tipo de Score", value: "TYPE_SCORE" },
                { text: "Periodo Información", value: "PERI_INFO" },
                { text: "Mensaje 1", value: "MESSAGE_1" },
                { text: "Mensaje 2", value: "MESSAGE_2" },
                { text: "Mensaje 3", value: "MESSAGE_3" },
                { text: "Score", value: "SCORE" },
                { text: "Número de Identificación", value: "NUME_NIDE" },
            ],
            scoreHistoricoHeaders: [
                { text: "Tipo de Score", value: "tipoScore" },
                { text: "Periodo Información", value: "periodoInformacion" },
                { text: "Mensaje 1", value: "message1" },
                { text: "Mensaje 2", value: "message2" },
                { text: "Valor Score", value: "valorScore" },
            ],

            // Datos de las tablas, que serán asignados dinámicamente
            scoreActualData: [],
            scoreHistoricoData: []
        };
    },
    computed: {
        VUE_APP_FEATHERS_SERVER() {
        return process.env.VUE_APP_FEATHERS_SERVER;
        },
    },
    methods: {
        descomponerFecha(fechaString) {
            if (fechaString.length !== 8) {
                throw new Error("La cadena debe tener exactamente 8 caracteres en el formato DDMMYYYY.");
            }

            const dia = fechaString.slice(0, 2);
            const mes = fechaString.slice(2, 4);
            const anio = fechaString.slice(4, 8);

            return `${dia}/${mes}/${anio}`;
        },
        // Filtro personalizado para búsquedas por columna
        // eslint-disable-next-line no-unused-vars
        customFilter(value, search, item) {
            if (!search) return true;
            return String(value).toLowerCase().includes(search.toLowerCase());
        },

        // Función para procesar el JSON y asignar los datos a las tablas
        loadDataFromJson(jsonData) {
            // Extrae ScoreActual y scoreHistorico si existen
            this.scoreActualData = jsonData.infoCrediticia?.ScoreActual || [];
            this.scoreHistoricoData = jsonData.infoCrediticia?.scoreHistorico || [];
        }
    },

    // created() {
    //   // Ejemplo de JSON dinámico; reemplaza esto con el JSON real que recibas
    //   const dynamicJson = {
    //     infoCrediticia: {
    //       ScoreActual: [
    //         {
    //           TYPE_SCORE: "8",
    //           PERI_INFO: "797589",
    //           MESSAGE_3: "",
    //           MESSAGE_2: "Relación entre el pago mínimo y el límite",
    //           MESSAGE_1: "Tendencia creciente en días de mora de productos TC",
    //           SCORE: "23",
    //           NUME_NIDE: "2000378435"
    //         }
    //       ],
    //       scoreHistorico: [
    //         {
    //           message2: "EXPERIENCIA CREDITICIA",
    //           tipoScore: 7,
    //           message1: "UTILIZACION DE PRODUCTOS",
    //           valorScore: 2470,
    //           periodoInformacion: 202409
    //         }
    //       ]
    //     }
    //   };

    //   // Cargar datos en las tablas
    //   this.loadDataFromJson(dynamicJson);
    // }
};
</script>