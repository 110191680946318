<!-- integracion: borrar funcion fnRevisarPendientes-->
<template>
  <v-container>
    <p class="text-h6 font-weight-regular mt-2">Revisión contrato cargado</p>
    <v-row>
      <v-col v-for="(item, i) in itemsContratoCard" :key="i" cols="4">
        <v-card outlined v-if="
          !router_path_filtro ||
          (router_path_filtro && router_path_filtro == item.original_estado)
        ">
          <v-card-text class="text-center">
            <!-- /**integracion */ -->
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <!-- /**integracion */ -->
            <p class="text--primary font-weight-regular text-h6">
              Contrato - parte {{ item.parte }}
            </p>
            <span v-if="!item.fisicoLink" size="52" class="mt-2">
              No hay archivo</span>

            <!-- <v-menu>
              <template v-slot:activator="{ attrs, on }">
                <v-btn text small v-bind="attrs" v-on="on" class="mt-n6">
                  <span>Estado</span>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in itemsEstado" :key="index" link>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-card-text>
          <v-card-text>
            <v-row justify="center" no-gutters>
              <v-col cols="12" md="12" sm="12">
                <v-select clearable :disabled="item.estado == 'Rechazado' || flag_gestion_terminada || !item.fisicoLink"
                  v-model="item.estado" :items="itemsEstado" label="Estado" rounded single-line dense
                  append-icon="mdi-chevron-down" class="px-1" @change="fnActualizarEstadoComplemento(item, i)">
                </v-select>
              </v-col>
              <v-col v-if="item.ultima_actualizacion_usuario">
                <span class="font-weight-regular text-caption">Ultima modificacion: {{
                  fnGetDate(item.ultima_actualizacion_fecha) }}<br>

                  Por: {{ item.ultima_actualizacion_usuario }}</span>

              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-space-around pb-6 mt-n2">
            <v-btn v-if="item.fisicoLink" x-small color="grey" outlined class="px-4 mr-n2" elevation="1"
              @click="fnContratoFisico(item)">
              Físico
            </v-btn>

            <v-btn x-small color="grey" outlined class="px-4 ml-n2" elevation="1" @click="fnContratoDigital(item)">
              Digital
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>



    <!-- ventana de dialogo para mostrar la fotografía del contrato físico -->
    <v-dialog v-model="dialogFisico" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Fotografía contrato parte {{ infoContrato.parte }}</v-card-title>

        <v-card-text>
          <v-img aspect-ratio="1.4" contain transition="scale-transition" alt="gotografía contrato"
            :src="VUE_APP_FEATHERS_SERVER + infoContrato.fisicoLink" />
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small @click="
            fnDescargarContratoFotografia(
              VUE_APP_FEATHERS_SERVER + infoContrato.fisicoLink,
              `Fotografía contrato parte ${infoContrato.parte}`
            )
            ">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogFisico = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana de dialogo para mostrar el contrato digital -->
    <v-dialog v-model="dialogDigital" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Contrato digital parte {{ infoContrato.parte }}</v-card-title>
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img alt="contrato digital" class="shrink" contain :src="computedContratoImagen(infoContrato.parte)" width="300" />
        </div>

        <v-card-actions class="justify-end pb-6">
          
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small @click="fnDescargarContratoDigital(infoContrato.parte )">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogDigital = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoRechazado" max-width="500" persistent>
      <v-card v-if="modalComplementoRechazadoData">
        <v-card-title class="headline">Rechazar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea rechazar el complemento
            <strong>{{
              modalComplementoRechazadoData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento para rectificación.
          </p>
          <p>
            *. Esta rectificación desaparecerá hasta que sea corregida por la
            supervisora.
          </p>
          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Motivo del rechazo" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="rechazarComplemento(modalComplementoRechazadoData)"
            :disabled="!comentarioValido">Sí, enviar a rectificación</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoPendiente" max-width="500" persistent>
      <v-card v-if="modalComplementoPendienteData">
        <v-card-title class="headline">Marcar como pendiente</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea marcar como pendiente el complemento
            <strong>{{
              modalComplementoPendienteData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento como pendiente.
          </p>

          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Comentario obligatorio:" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="spacer" dark @click="pendienteComplemento(modalComplementoPendienteData)"
            :disabled="!comentarioValido">Sí, marcar como pendiente</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-snackbar v-model="snackbar" timeout="3500" color="success" right top class="mt-14">
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Estado de complemento actualizado
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- /**LOADING BAR */ -->
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'vistaContrato',
  props: ['detalleEvaluacion'],
  data() {
    return {
      apiUrl: process.env.VUE_APP_FEATHERS_SERVER,
      snackbar: false,
      // por pendiente de complemento
      modalComplementoPendiente: false,
      modalComplementoPendienteData: null,
      modalComplementoPendienteIndex: null,
      /**por rechazo de complemento */
      modalComplementoRechazado: false,
      modalComplementoRechazadoData: null,
      modalComplementoRechazadoIndex: null,
      still_loading: true,
      comentario: '',
      rules: {
        required: (v) => !!v || 'El comentario es requerido',
      },
      /**integracion */
      contrato: null,
      /**integracion */
      //variables para las ventánas de dialogo que muestran las ventanas de los contratos
      dialogFisico: false,
      dialogDigital: false,

      //items temporales para las cards de el apartado de CONTRATO
      itemsContratoCard: [
        {
          id: null,
          parte: 1,
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'contrato_parte_uno_estado',
          complemento_nombre: 'Contrato parte uno',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          parte: 2,
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'contrato_parte_dos_estado',
          complemento_nombre: 'Contrato parte dos',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          parte: 3,
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'contrato_parte_tres_estado',
          complemento_nombre: 'Contrato parte tres',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
      ],

      infoContrato: {}, //variable para mostrar la parte del contrato en la ventana de diálogo cuando se da clic en los respectivos botones

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      itemsEstado: ['Aprobado', 'Rechazado', 'Pendiente'],
    };
  },
  computed: {
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    },
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    comentarioValido() {
      return !!this.comentario;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },

  },
  methods: {
    computedContratoImagen(contratoParte){
      if (this.detalleEvaluacion.tipo_de_persona_nacionalidad == 'extranjero') {
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVExtranjera1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVExtranjera2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVExtranjera3.jpg');
          default:
          return require('@/assets/images/contrato.jpg');
        }
      }else{
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVNacional1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVNacional2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVNacional3.jpg');
          default:
          return require('@/assets/images/contrato.jpg');
        }

      }
    },
    ...mapActions('Contratos', {
      findContratos: 'find',
      getContratos: 'get',
      patchContratos: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      createRectificaciones: 'create',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('Pendientes', {
      findPendientes: 'find',
      createPendientes: 'create',
      getPendientes: 'get',
      patchPendientes: 'patch',
    }),

    //
    async main() {
      /**integracion */

      console.log('hay nuevo detalleEvaluacion - contrato side');

      if (this.detalleEvaluacion && this.detalleEvaluacion.contrato_id) {
        /**obtener informacion de CONTRATO
         * Y SIGNARLO A itemsContratoCard
         */
        await this.getContratos(this.detalleEvaluacion.contrato_id).then(
          (r) => {
            /** */
            this.contrato = r;
            /**contrato primera parte */
            console.log('actualizar informacion de contrato primera parte');
            this.itemsContratoCard[0].id = this.detalleEvaluacion.contrato_id;
            this.itemsContratoCard[0].fisicoLink =
              this.contrato.contrato_parte_uno_fotografia;
            this.itemsContratoCard[0].estado =
              this.contrato.contrato_parte_uno_estado;
            this.itemsContratoCard[0].original_estado =
              this.contrato.contrato_parte_uno_estado;
            this.itemsContratoCard[0].ultima_actualizacion_usuario =
              this.contrato.ultima_modificacion_por_usuario_nombre_pt1;
            this.itemsContratoCard[0].ultima_actualizacion_fecha =
              this.contrato.ultima_modificacion_fecha_pt1;
            /** */
            /**contrato segunda parte */
            console.log('actualizar informacion de contrato segunda parte');
            this.itemsContratoCard[1].id = this.detalleEvaluacion.contrato_id;
            this.itemsContratoCard[1].fisicoLink =
              this.contrato.contrato_parte_dos_fotografia;
            this.itemsContratoCard[1].estado =
              this.contrato.contrato_parte_dos_estado;
            this.itemsContratoCard[1].original_estado =
              this.contrato.contrato_parte_dos_estado;
            this.itemsContratoCard[1].ultima_actualizacion_usuario =
              this.contrato.ultima_modificacion_por_usuario_nombre_pt2;
            this.itemsContratoCard[1].ultima_actualizacion_fecha =
              this.contrato.ultima_modificacion_fecha_pt2;
            /** */
            /**contrato tercera parte */
            console.log('actualizar informacion de contrato tercera parte');
            this.itemsContratoCard[2].id = this.detalleEvaluacion.contrato_id;
            this.itemsContratoCard[2].fisicoLink =
              this.contrato.contrato_parte_tres_fotografia;
            this.itemsContratoCard[2].estado =
              this.contrato.contrato_parte_tres_estado;
            this.itemsContratoCard[2].original_estado =
              this.contrato.contrato_parte_tres_estado;
            this.itemsContratoCard[2].ultima_actualizacion_usuario =
              this.contrato.ultima_modificacion_por_usuario_nombre_pt3;
            this.itemsContratoCard[2].ultima_actualizacion_fecha =
              this.contrato.ultima_modificacion_fecha_pt3;
            /** */
            /** */
            let vm = this;
            setTimeout(() => {
              vm.still_loading = false;
            }, 500);
          }
        );
      }
      /**integracion */
    },
    //
    fn_check_if_pendiente_debe_actualizarse(complemento) {

      this.findPendientes({
        query: {
          STATUS: 1,
          gestion_id: this.detalleEvaluacion.id,
          complemento_id: complemento.id,
          status_tbl_attribute: complemento.tbl_attribute
        }
      }).then(result => {
        // Verifica si existe algún pendiente
        if (result.data && result.data.length > 0) {
          const pendiente = result.data[0]; // Supongamos que solo necesitas el primero

          // Realiza el patch con el id del pendiente encontrado
          this.patchPendientes([pendiente.id, { STATUS: 2 }]);
        } else {
          console.error('No se encontró ningún pendiente, al aprobar el complemento');
        }
      }).catch(error => {
        console.error('Error al buscar el pendiente:', error);
      });
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },

    /*** */
    fnActualizarEstadoComplemento(complemento, index) {
      /** ACTUALIZACION DIRECTA A LA BASE DE DATOS POR ESTADOS APROBADO O PENDIENTE */
      if (complemento.estado == 'Aprobado') {
        const payload = {
          [complemento.tbl_attribute]: complemento.estado,
        };

        this.patchContratos([this.contrato.id, payload]).then(() => {
          this.snackbar = true;
        });

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento);
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if (complemento.estado == 'Rechazado') {

        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoRechazado = true;
        this.modalComplementoRechazadoData = complemento;
        this.modalComplementoRechazadoIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento);
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if (complemento.estado == 'Pendiente') {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoPendiente = true;
        this.modalComplementoPendienteData = complemento;
        this.modalComplementoPendienteIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }

    },
    async rechazarComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };

      await this.patchContratos([this.contrato.id, payload]).then(async (r) => {
        console.log('contrato actualizado', r);
      });

      await this.createRectificaciones({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'deudor',
        rectificacion_tipo: 'contrato',
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        rectificacion_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoRechazado = false;
        this.modalComplementoRechazadoData = null;
        this.comentario = null;
      });
    },
    async pendienteComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };

      await this.patchContratos([this.contrato.id, payload]).then(async (r) => {
        console.log('complemento actualizado', r);
      });

      await this.createPendientes({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'deudor',
        pendiente_tipo: 'contrato',
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        pendiente_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // pendiente_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoPendiente = false;
        this.modalComplementoPendienteData = null;
        this.comentario = null;
      });
    },
    cancelar() {
      /**
       * reset estado a default value
       */
      if (this.modalComplementoRechazadoData) {
        this.itemsContratoCard[this.modalComplementoRechazadoIndex].estado =
          this.modalComplementoRechazadoData.original_estado;
      }

      if (this.modalComplementoPendienteData) {
        this.itemsContratoCard[this.modalComplementoPendienteIndex].estado =
          this.modalComplementoPendienteData.original_estado;
      }

      /** */
      this.modalComplementoRechazado = false;
      this.modalComplementoRechazadoData = null;
      this.modalComplementoRechazadoIndex = null;
      /** */
      this.modalComplementoPendiente = false;
      this.modalComplementoPendienteData = null;
      this.modalComplementoPendienteIndex = null;
      /** */
      this.comentario = null;
    },
    //-----funciones cuando se da clic en los botones de FÍSICO - DIGITAL en las cards del apartado de CONTRATO

    //clic en botón FÍSICO
    fnContratoFisico(item) {
      this.infoContrato = Object.assign(item);
      console.log('se dio clic en el botón', this.infoContrato); // comprobando que si se da clic sobre items diferentes dependiendo de en que card esté el botón
      this.dialogFisico = true;
    },

    //clic en botón DIGITAL
    fnContratoDigital(item) {
      this.infoContrato = Object.assign(item);
      console.log('se dio clic en el botón', this.infoContrato);
      this.dialogDigital = true;

      /** */

    },
    fnDescargarArchivo(url, tipo, nombreArchivo) {
      axios
        .get(url, {
          responseType: "blob",
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((response) => {
          let urlDown = URL.createObjectURL(
            new Blob([response.data], { type: tipo })
          );
          let link = document.createElement("a");
          link.href = urlDown;
          link.setAttribute("download", nombreArchivo);
          document.body.appendChild(link);
          link.click();
        });
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en las respectivas ventanas de dialogo, fotografía de contrato y contrato digital
    /**implementacion */
    async fnDescargarContratoFotografia(ung_url, tipo) {
      const response = await fetch(ung_url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image_${tipo}.jpg`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    /**implementacion */
    fnDescargarContratoDigital(pagina) {

      /** */
      let fileName = `Contrato digital gestión ${this.detalleEvaluacion.id} parte ${pagina}.pdf`;
      let url = `${this.apiUrl}generar-contrato-fisico-pdf/${this.detalleEvaluacion.id}?pagina=${pagina}`;
      this.fnDescargarArchivo(url, "application/pdf", fileName);
    },
  },
  watch: {},

  //para verificar si se tiene algun pendiente (únicamente para pruebas)
  mounted() {
    let vm = this;
    setTimeout(() => {
      vm.main();
    }, 500);

    /**actualizar datos al actualizar o crear compleentos de gestion */
    const {
      Contratos,
      DocumentosDeIdentificacion,
      Recibos,
      FotografiasDeCasa,
      Referencias,
      RecibosComerciales
    } = this.$FeathersVuex.api;
    Contratos.on('patched', () => {
      this.main();
    });
    DocumentosDeIdentificacion.on('patched', () => {
      this.main();
    });
    Recibos.on('patched', () => {
      this.main();
    });
    FotografiasDeCasa.on('patched', () => {
      this.main();
    });
    Referencias.on('patched', () => {
      this.main();
    });
    RecibosComerciales.on('patched', () => {
      this.main();
    });
    /**actualizar datos al actualizar o crear compleentos de gestion, fin */
  },
};
</script>

<style scoped>
.v-select .v-input--dense .v-select__selection--comma {
  margin: 0px 0px 0px 20px;
}
</style>
