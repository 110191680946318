<template>
  <v-container fluid>
    <!-- PARTE PRINCIPAL -->
    <v-row class="px-2 mt-n6">
      <!-- columna izquierda con la informacíon de la persona -->
      <v-col cols="4" md="4" xl="3">
        <v-card tile>
          <div class="text-center">
            <v-avatar size="58" color="#031D6A" class="my-3">
              <v-icon dark size="48"> mdi-account </v-icon>
            </v-avatar>
            <p class="text-h6 text--primary font-weight-regular mt-n2">
              {{ detalleEvaluacion.fiador_primer_nombre }}
              {{ detalleEvaluacion.fiador_primer_apellido }}
            </p>
          </div>
          <div class="px-4 mt-n4 pb-2">
            <table>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de documento identifiación
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_dpi }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Identificación extendida en
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{
                    detalleEvaluacion.fiador_identificacion_extendida_en_departamento
                  }}
                </td>
              </tr>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Estado de la gestion
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.estado }}
                </td>
              </tr>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Buro resultado
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span :class="getBuroColor(detalleEvaluacion.fiador_resultado_evaluacion) + '--text'">{{
                    detalleEvaluacion.fiador_resultado_evaluacion }}</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_primer_nombre }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <!-- mostramos un guión mayor si no contiene el campo, si es una cadena vacía  -->
                  <span v-if="detalleEvaluacion.fiador_segundo_nombre">{{
                    detalleEvaluacion.fiador_segundo_nombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.fiador_segundo_nombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.fiador_tercer_nombre">{{
                    detalleEvaluacion.tercer_nombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.fiador_tercer_nombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_primer_apellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_segundo_apellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.fiador_tercer_apellido_o_casada">{{
                    detalleEvaluacion.fiador_tercer_apellido_o_casada
                  }}</span>
                  <span v-if="!detalleEvaluacion.fiador_tercer_apellido_o_casada">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Fecha nacimiento
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{
                    fnGetUTCDate(detalleEvaluacion.fiador_fecha_de_nacimiento)
                  }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección del fiador
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_direccion_consejera }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Fiador dirección entrega
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_direccion_de_entrega }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tipo de vivienda
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_vivienda_tipo }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Ubicación
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.fiador_gestion_ubicacion_lat">{{
                    detalleEvaluacion.fiador_gestion_ubicacion_lat }},
                    {{ detalleEvaluacion.fiador_gestion_ubicacion_lng }}</span>
                  <span v-if="!detalleEvaluacion.fiador_gestion_ubicacion_lat">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de celular
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_celular }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de casa
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_telefono_casa }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección de trabajo
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_trabajo_direccion }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de trabajo
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_trabajo_telefono }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  NIT
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.fiador_nit }}
                </td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>

      <!-- columna derecha -->
      <v-col cols="8" md="8" xl="9">
        <v-card tile min-height="570">
          <v-tabs v-model="tab" color="#031D6A" left>

            <v-tab :disabled="!flag_show_complemento" key="complemento">COMPLEMENTOS</v-tab>
            <v-tab :disabled="!flag_show_evaluacion" key="evaluacion">EVALUACIÓN</v-tab>
            <v-tab :disabled="!flag_show_referencias" key="referencias">REFERENCIAS</v-tab>
            <v-tab v-if="flag_show_compromisos" key="compromisos">COMPROMISOS DE PAGO</v-tab>
            <v-tab active v-if="flag_show_rectifiaciones" key="rectifiaciones">RECTIFICACIONES</v-tab>

            <!-- maquetado vista COMPLEMENTOS -->
            <v-tab-item>
              <vistaComplementos :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <!-- maquetado vista EVALUACIÓN -->
            <v-tab-item>
              <vistaEvaluacion :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <!-- maquetado vista REFERENCIAS -->
            <v-tab-item>
              <vistaReferencias :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <v-tab-item v-if="flag_show_compromisos">
              <vistaCompromisosFiador :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <v-tab-item v-if="flag_show_rectifiaciones">
              <vistaRectificaciones :prop_perfil="'fiador'" :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
          </v-tabs>

          <!-- apartado para la sección de comentarios -->
          <v-container>
            <div class="mt-n5">
              <v-row>
                <v-col cols="6" v-show="!router_path_filtro ||
                  (router_path_filtro && router_path_filtro == 'Rechazado')
                  ">
                  <!-- <v-textarea
                    ref="comentarioUno"
                    v-model="comentarioUno"
                    label="Agregar comentario"
                    auto-grow
                    outlined
                    rows="4"
                    row-height="25"
                  ></v-textarea>

                  <v-btn
                    small
                    color="blue"
                    outlined
                    class="px-4 mt-n8"
                    elevation="2"
                    @click="fnAgregarComentarioUno()"
                  >
                    AGREGAR COMENTARIO
                  </v-btn> -->

                  <!-- Card para mostrar el/los comentarios -->
                  <span class="text--secondary" v-if="
                    !filtered_restricciones_comentarios ||
                    filtered_restricciones_comentarios.length == 0
                  ">Sin rectificaciones</span>
                  <v-card class="mb-2" outlined min-height="130" v-for="(
                      comentario, j
                    ) in filtered_restricciones_comentarios" :key="j">
                    <v-card-title>
                      {{
                        comentario.complemento_nombre
                          .replace(/_/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase())
                          .replace(/Estado/g, ' ')
                      }}
                    </v-card-title>
                    <v-card-text>
                      <p>
                        <span class="font-weight-medium">Motivo:</span>
                        {{ comentario.rectificacion_comentario }}
                      </p>
                      <hr class="mb-2" style="
                          align: left;
                          max-width: 150px;
                          border: 1px solid #eeeeee;
                        " />
                      <p>
                        Rectificaciíon creada por:
                        {{ comentario.CREADO_POR_USUARIO }} <br />
                        Fecha de creación:
                        {{ fnGetDateTime(comentario.createdAt) }}
                      </p>
                    </v-card-text>
                    <v-card-actions> </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-textarea ref="comentario_interno" v-model="comentario_interno" label="Agregar comentario" auto-grow
                    outlined rows="4" row-height="25"></v-textarea>

                  <v-btn small color="blue" outlined class="px-4 mr-1 mt-n8" elevation="2"
                    @click="fn_agregar_comentario_interno()">
                    AGREGAR COMENTARIO INTERNO
                  </v-btn>
                  <v-btn small color="cyan" outlined class="px-4 mt-n8" elevation="2"
                    @click="fn_agregar_comentario_externo()">
                    Enviar comentario a agente de comercio
                  </v-btn>
                  <v-card flat v-if="filtered_comentarios_internos.length <= 0">
                    <v-card-text>Sin comentarios</v-card-text>
                  </v-card>

                  <!-- Card para mostrar el/los comentarios -->
                  <v-card class="mb-2" outlined min-height="130"
                    v-for="(intComentario, j) in filtered_comentarios_internos" :key="j">
                    <v-container>
                      <v-row>
                        <v-col cols="1">
                          <v-icon v-if="!intComentario.flag_comentario_externo" class="mt-3">mdi-comment</v-icon>
                          <v-icon v-if="intComentario.flag_comentario_externo" class="mt-3">mdi-cellphone</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <p class="text-body-2 font-weight-medium text--secondary">
                            Comentario por:
                            {{ intComentario.CREADO_POR_USUARIO }}
                          </p>
                          <p class="text-body-2 font-weight-medium text--secondary mt-n4">
                            Fecha: {{ fnGetDateTime(intComentario.createdAt) }}
                          </p>
                        </v-col>
                      </v-row>
                      <p class="text-body-2 font-weight-regular text--secondary mt-n5">
                        {{ intComentario.comentario }}
                      </p>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');

import vistaComplementos from './vistaComplementosFiador.vue';
import vistaEvaluacion from './vistaEvaluacionFiador.vue';
import vistaReferencias from './vistaReferenciasFiador.vue';
import vistaCompromisosFiador from './vistaCompromisosFiador.vue';
import vistaRectificaciones from './../deudor/vistaRectificaciones.vue';
export default {
  name: 'FiadorPage',
  props: ['detalleEvaluacion'],
  components: {
    vistaComplementos, //componente que contiene la info de la ventana de COMPLEMENTOS
    vistaEvaluacion, // para la info que se muestra en EVALUACIÓN
    vistaReferencias, //para la info que se muestra en REFERENCIAS
    vistaCompromisosFiador, //para la info que se muestra en vistaCompromisosFiador
    vistaRectificaciones, //para la info que se muestra en vistaRectificaciones
  },
  data() {
    return {
      tab: 0,
      //
      comentario_interno: '',
      comentarios_internos: [],
      //
      comentarios_rectificaciones: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_id() {
      return this.$route.params.id;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
    filtered_restricciones_comentarios() {
      if (!this.router_path_filtro) {
        switch (this.tab) {
          case 0:
            return this.comentarios_rectificaciones.filter((item) =>
              [
                'documento de identificación',
                'recibo de servcio',
                'fotografía de casa',
              ].includes(item.rectificacion_tipo)
            );
          case 1:
            return this.comentarios_rectificaciones.filter(
              (item) => item.rectificacion_tipo === 'evaluacion'
            );
          case 2:
            return this.comentarios_rectificaciones.filter(
              (item) => item.rectificacion_tipo === 'referencias'
            );
          default:
            return this.comentarios_rectificaciones;
        }
      }

      if (
        this.router_path_filtro &&
        this.router_path_filtro == 'CompromisosDePago'
      ) {
        return this.comentarios_rectificaciones.filter(
          (item) => item.rectificacion_tipo === 'compromiso'
        );
      } else {
        return this.comentarios_rectificaciones;
      }
    },
    filtered_comentarios_internos() {
      switch (this.tab) {
        case 0:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'complementos'
          );
        case 1:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'evaluacion'
          );
        case 2:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'referencias'
          );
        default:
          return this.comentarios_internos;
      }
    },
    /** */
    flag_show_complemento() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        /** complemento doc */
        if (this.detalleEvaluacion.fiador_documentos_de_identificacion) {
          let identifiacion_documento =
            this.detalleEvaluacion.fiador_documentos_de_identificacion;

          if (
            identifiacion_documento.documento_de_identificacion_frontal_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            identifiacion_documento.documento_de_identificacion_anverso_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            identifiacion_documento.documento_de_identificacion_con_persona_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo */
        if (this.detalleEvaluacion.fiador_recibo_de_servicio) {
          if (
            this.detalleEvaluacion.fiador_recibo_de_servicio.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento casa */
        if (this.detalleEvaluacion.fiador_foto_casa) {
          if (
            this.detalleEvaluacion.fiador_foto_casa.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        return false;
      }
    },
    flag_show_evaluacion() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        if (this.detalleEvaluacion.evaluacion) {
          return true;
        }
        return false;
      }
    },
    flag_show_referencias() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        if (this.detalleEvaluacion) {
          if (
            this.detalleEvaluacion.fiador_referencia_titular &&
            this.detalleEvaluacion.fiador_referencia_titular.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.fiador_referencia_familiar_1 &&
            this.detalleEvaluacion.fiador_referencia_familiar_1.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.fiador_referencia_familiar_2 &&
            this.detalleEvaluacion.fiador_referencia_familiar_2.estado ==
            this.router_path_filtro
          ) {
            return true;
          }

          if (
            this.detalleEvaluacion.fiador_referencia_personal_1 &&
            this.detalleEvaluacion.fiador_referencia_personal_1.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.fiador_referencia_personal_2 &&
            this.detalleEvaluacion.fiador_referencia_personal_2.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }

        return false;
      }
    },
    flag_show_compromisos() {
      if (
        this.router_path_filtro &&
        this.router_path_filtro == 'CompromisosDePago'
      ) {
        return true;
      } else {
        return false;
      }
    },
    flag_show_rectifiaciones() {
      if (this.router_path_filtro && this.router_path_filtro == 'Rechazado') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getBuroColor(resultado) {

      if (resultado == 'rechazado' || resultado == 'RECHAZADO' || resultado == 'DENEGADO' || resultado == 'DENEGAR') {
        return 'red'
      } else if (resultado == 'REVISIÓN' || resultado == 'revision' || resultado == 'revisión') {
        return 'orange'
      } else if (resultado == 'aprobado' || resultado == 'APROBADO') {
        return 'green'
      } else {
        return 'grey'
      }
    },
    ...mapActions('Rectificaciones', {
      findRectificaciones: 'find',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('ComentariosInternosPorGestion', {
      createComentariosInternosPorGestion: 'create',
      findComentariosInternosPorGestion: 'find',
      getComentariosInternosPorGestion: 'get',
      patchComentariosInternosPorGestion: 'patch',
    }),
    async main() {
      let vm = this;
      await setTimeout(() => {
        vm.findRectificaciones({
          query: {
            gestion_id: vm.detalleEvaluacion.id,
            $limit: 100,
            perfil_de_usuario: 'fiador',
          },
        }).then((r) => {
          vm.comentarios_rectificaciones = r.data;
        });
      }, 300);

      await this.findComentariosInternosPorGestion({
        query: {
          gestion_id: this.router_path_id,
          $limit: 100,
          perfil_de_usuario: 'fiador',
          $sort: {
            createdAt: -1,
          },
        },
      }).then((r) => {
        console.log('comentarios internos: ', r);
        this.comentarios_internos = r.data;
      });
    },

    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetUTCDate(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },

    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    //clic en los botones de AGREGAR COMENTARIO
    fnAgregarComentarioUno() {
      console.log('comentario uno: ', this.comentarioUno);
      this.$refs.comentarioUno.reset(); //reiniciamos el text-fiel de comentario
    },
    async fn_agregar_comentario_interno() {
      console.log('comentario dos: ', this.comentario_interno);

      /**
       * tipo de comentario (doc identificaicon, foto casa, etc...)
       */
      let tipo_de_comentario = '';
      switch (this.tab) {
        case 0:
          tipo_de_comentario = 'complementos';
          break;
        case 1:
          tipo_de_comentario = 'evaluacion';
          break;
        case 2:
          tipo_de_comentario = 'referencias';
          break;
        case 3:
          tipo_de_comentario = 'compromiso de pago';
          break;
        default:
          tipo_de_comentario = '';
      }

      if (this.router_path_filtro && this.router_path_filtro == 'CompromisosDePago') {
        tipo_de_comentario = 'compromiso';
      }

      /** */

      await this.createComentariosInternosPorGestion({
        gestion_id: this.detalleEvaluacion.id,
        comentario_tipo: tipo_de_comentario,
        perfil_de_usuario: 'fiador',

        comentario: this.comentario_interno,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then((r) => {
        this.snackbar = true;
        this.comentarios_internos.push(r);
        this.$refs.comentario_interno.reset(); //reiniciamos el text-fiel de comentario
      });
    },
    async fn_agregar_comentario_externo() {
      console.log('comentario dos: ', this.comentario_interno);

      /**
       * tipo de comentario (doc identificaicon, foto casa, etc...)
       */
      let tipo_de_comentario = '';
      switch (this.tab) {
        case 0:
          tipo_de_comentario = 'complementos';
          break;
        case 1:
          tipo_de_comentario = 'evaluacion';
          break;
        case 2:
          tipo_de_comentario = 'referencias';
          break;
        case 3:
          tipo_de_comentario = 'compromiso de pago';
          break;
        default:
          tipo_de_comentario = '';
      }

      if (this.router_path_filtro && this.router_path_filtro == 'CompromisosDePago') {
        tipo_de_comentario = 'compromiso';
      }

      /** */

      await this.createComentariosInternosPorGestion({
        gestion_id: this.detalleEvaluacion.id,
        comentario_tipo: tipo_de_comentario,
        perfil_de_usuario: 'fiador',
        flag_comentario_externo: 1,

        comentario: this.comentario_interno,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then((r) => {
        this.snackbar = true;
        this.comentarios_internos.push(r);
        this.$refs.comentario_interno.reset(); //reiniciamos el text-fiel de comentario
      });
    },
  },
  async mounted() {
    await this.main();

    /** */
    const { Rectificaciones } = this.$FeathersVuex.api;
    Rectificaciones.on('created', () => {
      this.main();
    });

    /** */
    if (this.router_path_filtro == 'CompromisosDePago') {
      this.tab = 3;
    }
    let vm = this;
    setTimeout(() => {
      if (vm.router_path_filtro == 'Rechazado') {
        vm.tab = 3;
      }
    }, 300);
  },
  watch: {},
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px;
}
</style>
