<template>
  <v-container>
    <p class="text-h6 font-weight-regular">Documento de identificación</p>

    <v-row>
      <v-col
        v-for="(item, i) in itemsDocumentoIdenfiticacion"
        :key="i"
        cols="4"
      >
        <v-card
          outlined
          min-height="250"
          v-if="
            !router_path_filtro ||
            (router_path_filtro && router_path_filtro == item.original_estado)
          "
        >
        <v-card-text  class="text-center"><!-- /**integracion */ -->
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>

            <!-- /**integracion */ -->
            <p class="text--primary font-weight-regular text-h6">
              {{ item.title }}
            </p></v-card-text>
        <v-card-text>
          
            <!-- /** DOCUMENTOS DE IDENTIFICACION */ -->
            <v-row justify="center">
              <v-col cols="12">
                <v-select
                  clearable
                  :disabled="item.estado == 'Rechazado' || flag_gestion_terminada"
                  v-model="item.estado"
                  :items="itemsEstado"
                  label="Estado"
                  rounded
                  single-line
                  dense
                  append-icon="mdi-chevron-down"
                  class="px-1"
                  @change="fnActualizarEstadoComplemento(item, i)"
                >
                </v-select>
              </v-col>
              <v-col v-if="item.ultima_actualizacion_usuario">
                <span class="font-weight-regular text-caption">Ultima modificacion: {{
                  fnGetDate(item.ultima_actualizacion_fecha) }}<br>

                  Por: {{ item.ultima_actualizacion_usuario }}</span>

              </v-col>
            </v-row>
        </v-card-text>

          <v-card-actions class="justify-center pb-6 mt-n2">
            <v-btn
              x-small
              color="grey"
              outlined
              class="px-4 mr-n2"
              elevation="1"
              @click="fnMostrarFotografia(item)"
            >
              FOTOGRAFÍA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <p class="text-h6 font-weight-regular mt-2">Otros complementos</p>

    <!-- cards para mostrar los OTROS COMPLEMENTOS -->
    <v-row class="mt-n5">
      <v-col v-for="(item, i) in itemsOtrosComplementos" :key="i" cols="4">
        <v-card
          outlined
          min-height="210"
          v-if="
            !router_path_filtro && item.id ||
            (router_path_filtro && router_path_filtro == item.original_estado && item.id )
          "
        >
        <v-card-text class="text-center">
          <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <p class="text--primary font-weight-regular text-h6">
              {{ item.title }}
            </p>

        </v-card-text>
        <v-card-text>
          <!-- /** OTROS COMPLEMENTOS */ -->
          <v-row justify="center">
              <v-col cols="12">
                <v-select
                  clearable
                  :disabled="item.estado == 'Rechazado' || flag_gestion_terminada"
                  v-model="item.estado"
                  :items="itemsEstado"
                  label="Estado"
                  rounded
                  single-line
                  dense
                  append-icon="mdi-chevron-down"
                  class="px-1"
                  @change="fnActualizarEstadoComplemento(item, i)"
                >
                </v-select>
              </v-col>
              <v-col v-if="item.ultima_actualizacion_usuario">
                <span class="font-weight-regular text-caption">Ultima modificacion: {{
                  fnGetDate(item.ultima_actualizacion_fecha) }}<br>

                  Por: {{ item.ultima_actualizacion_usuario }}</span>

              </v-col>
            </v-row>
        </v-card-text>
   
          <v-card-actions class="justify-center pb-6 mt-n2">
            <v-btn
              x-small
              color="grey"
              outlined
              class="px-4 mr-n2"
              elevation="1"
              @click="fnMostrarFotografia(item)"
            >
              FOTOGRAFÍA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- ventana de dialogo para mostrar la/las fotografía -->
    <!-- ventana de dialogo para mostrar la fotografía del contrato físico -->
    <v-dialog v-model="dialogDigital" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1"
          >Fotografía contrato parte {{ infoComplementos.parte }}</v-card-title
        >

        <v-card-text>
          <v-img
            aspect-ratio="1.4"
            contain
            transition="scale-transition"
            alt="gotografía contrato"
            :src="VUE_APP_FEATHERS_SERVER + infoComplementos.fisicoLink"
          />
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="#031D6A"
            class="px-5 white--text mr-4"
            small
            @click="
              fnDescargarContratoFotografia(
                VUE_APP_FEATHERS_SERVER + infoComplementos.fisicoLink,
                `${infoComplementos.title}`
              )
            "
          >
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogDigital = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoRechazado" max-width="500" persistent>
      <v-card v-if="modalComplementoRechazadoData">
        <v-card-title class="headline">Rechazar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea rechazar el complemento
            <strong>{{
              modalComplementoRechazadoData.complemento_nombre
            }}</strong
            >?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento para rectificación.
          </p>
          <p>
            *. Esta rectificación desaparecerá hasta que sea corregida por la
            supervisora.
          </p>
          <v-text-field
            ref="motivorechazocomentario"
            v-model="comentario"
            label="Motivo del rechazo"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            @click="rechazarComplemento(modalComplementoRechazadoData)"
            :disabled="!comentarioValido"
            >Sí, enviar a rectificación</v-btn
          >
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoPendiente" max-width="500" persistent>
      <v-card v-if="modalComplementoPendienteData">
        <v-card-title class="headline">Marcar como pendiente</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea marcar como pendiente el complemento
            <strong>{{
              modalComplementoPendienteData.complemento_nombre
            }}</strong
            >?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento como pendiente.
          </p>
         
          <v-text-field
            ref="motivorechazocomentario"
            v-model="comentario"
            label="Comentario obligatorio:"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="spacer"
            dark
            @click="pendienteComplemento(modalComplementoPendienteData)"
            :disabled="!comentarioValido"
            >Sí, marcar como pendiente</v-btn
          >
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-snackbar
      v-model="snackbar"
      timeout="3500"
      color="success"
      right
      top
      class="mt-14"
    >
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Estado de complemento actualizado
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- /**LOADING BAR */ -->
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'vistaComplementos',
  props: ['detalleEvaluacion'],
  data() {
    return {
      snackbar: false,
      // por pendiente de complemento
      modalComplementoPendiente: false,
      modalComplementoPendienteData: null,
      modalComplementoPendienteIndex: null,
      //
      documento_de_identificacion: null,
      recibo: null,
      fotoCasa: null,
      /**por rechazo de complemento */
      modalComplementoRechazado: false,
      modalComplementoRechazadoData: null,
      modalComplementoRechazadoIndex: null,
      still_loading: true,
      comentario: '',
      rules: {
        required: (v) => !!v || 'El comentario es requerido',
      },
      //variables para las ventanas de diálogo para mostras las distintas fotografías
      dialogDigital: false,

      //info para mostrar en las cards de documento de identificación
      itemsDocumentoIdenfiticacion: [
        {
          id: null,
          title: 'Frontal',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'documento_de_identificacion_frontal_estado',
          complemento_nombre: 'Documento de identificacion frontal',
          API: 'patcDocumentosDeIdentificacion',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          title: 'Reverso',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'documento_de_identificacion_anverso_estado',
          complemento_nombre: 'Documento de identificacion reverso',
          API: 'patcDocumentosDeIdentificacion',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          title: 'Persona sosteniendo documento',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'documento_de_identificacion_con_persona_estado',
          complemento_nombre: 'Documento de identificacion con persona',
          API: 'patcDocumentosDeIdentificacion',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
      ],

      //info para mostrar en las cards de otros complementos
      itemsOtrosComplementos: [
        {
          id: null,
          title: 'Recibo servicios',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'estado',
          complemento_nombre: 'Recibo de servicio',
          API: 'patcRecibos',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          title: 'Fotografía casa',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'estado',
          complemento_nombre: 'Fotografía de casa',
          API: 'patcFotografiasDeCasa',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
        {
          id: null,
          title: 'Recibo comercial #1',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'estado',
          complemento_nombre: 'Recibo comercial 1',
          API: 'patchRecibosComerciales',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },{
          id: null,
          title: 'Recibo comercial #2',
          fisicoLink: null,
          digitalLink: null,
          estado: null,
          original_estado: null,
          tbl_attribute: 'estado',
          complemento_nombre: 'Recibo comercial 2',
          API: 'patchRecibosComerciales',
          ultima_actualizacion_fecha: null,
          ultima_actualizacion_usuario: null
        },
       
      ],

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      itemsEstado: ['Aprobado', 'Rechazado', 'Pendiente'],

      infoComplementos: {}, //para almacenar la info cuando damos clic en algún botón de FOTOGRAFÍA
    };
  },

  methods: {
    ...mapActions('Recibos', {
      findRecibos: 'find',
      getRecibos: 'get',
      patchRecibos: 'patch',
    }),
    ...mapActions('RecibosComerciales', {
      findRecibosComerciales: 'find',
      getRecibosComerciales: 'get',
      patchRecibosComerciales: 'patch',
    }),
    ...mapActions('DocumentosDeIdentificacion', {
      findDocumentosDeIdentificacion: 'find',
      getDocumentosDeIdentificacion: 'get',
      patchDocumentosDeIdentificacion: 'patch',
    }),
    ...mapActions('FotografiasDeCasa', {
      findFotografiasDeCasa: 'find',
      getFotografiasDeCasa: 'get',
      patchFotografiasDeCasa: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      createRectificaciones: 'create',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('Pendientes', {
      findPendientes: 'find',
      createPendientes: 'create',
      getPendientes: 'get',
      patchPendientes: 'patch',
    }),
    //
    /**integracion */

    async main() {
      console.log('hay nuevo detalleEvaluacion - complementos side');

      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.fiador_documento_de_identificacion_id
      ) {
        /**obtener informacion de documento de identificacion
         * Y SIGNARLO A itemsDocumentoIdenfiticacion
         */
        await this.getDocumentosDeIdentificacion(
          this.detalleEvaluacion.fiador_documento_de_identificacion_id
        ).then((r) => {
          /** */
          console.log('current bd documento de identificacion: ', r);
          this.documento_de_identificacion = r;

          /**documento_de_identificacion_frontal */
          console.log(
            'actualizar informacion documento de identificacion documento_de_identificacion_frontal'
          );
          this.itemsDocumentoIdenfiticacion[0].id =
            this.documento_de_identificacion.id;
          this.itemsDocumentoIdenfiticacion[0].fisicoLink =
            this.documento_de_identificacion.documento_de_identificacion_frontal;
          this.itemsDocumentoIdenfiticacion[0].estado =
            this.documento_de_identificacion.documento_de_identificacion_frontal_estado;
          this.itemsDocumentoIdenfiticacion[0].original_estado =
            this.documento_de_identificacion.documento_de_identificacion_frontal_estado;
          this.itemsDocumentoIdenfiticacion[0].ultima_actualizacion_usuario =
            this.documento_de_identificacion.ultima_modificacion_por_usuario_nombre_anverso;
          this.itemsDocumentoIdenfiticacion[0].ultima_actualizacion_fecha =
            this.documento_de_identificacion.ultima_modificacion_fecha_anverso;
            
          /** */
          /*documento_de_identificacion_anverso */
          console.log(
            'actualizar informacion documento de identificacion documento_de_identificacion_anverso'
          );
          this.itemsDocumentoIdenfiticacion[1].id =
            this.documento_de_identificacion.id;
          this.itemsDocumentoIdenfiticacion[1].fisicoLink =
            this.documento_de_identificacion.documento_de_identificacion_anverso;
          this.itemsDocumentoIdenfiticacion[1].estado =
            this.documento_de_identificacion.documento_de_identificacion_anverso_estado;
          this.itemsDocumentoIdenfiticacion[1].original_estado =
            this.documento_de_identificacion.documento_de_identificacion_anverso_estado;
          this.itemsDocumentoIdenfiticacion[1].ultima_actualizacion_usuario =
            this.documento_de_identificacion.ultima_modificacion_por_usuario_nombre_reverso;
          this.itemsDocumentoIdenfiticacion[1].ultima_actualizacion_fecha =
            this.documento_de_identificacion.ultima_modificacion_fecha_reverso;
          /** */
          /**documento_de_identificacion_con_persona */
          console.log(
            'actualizar informacion documento de identificacion documento_de_identificacion_con_persona'
          );
          this.itemsDocumentoIdenfiticacion[2].id =
            this.documento_de_identificacion.id;
          this.itemsDocumentoIdenfiticacion[2].fisicoLink =
            this.documento_de_identificacion.documento_de_identificacion_con_persona;
          this.itemsDocumentoIdenfiticacion[2].estado =
            this.documento_de_identificacion.documento_de_identificacion_con_persona_estado;
          this.itemsDocumentoIdenfiticacion[2].original_estado =
            this.documento_de_identificacion.documento_de_identificacion_con_persona_estado;
          this.itemsDocumentoIdenfiticacion[2].ultima_actualizacion_usuario =
            this.documento_de_identificacion.ultima_modificacion_por_usuario_nombre_con_persona;
          this.itemsDocumentoIdenfiticacion[2].ultima_actualizacion_fecha =
            this.documento_de_identificacion.ultima_modificacion_fecha_con_persona;          /** */
        });
      }

      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.fiador_recibo_de_servicio_id
      ) {
        await this.getRecibos(
          this.detalleEvaluacion.fiador_recibo_de_servicio_id
        ).then((r) => {
          /** */
          console.log('current bd recibo: ', r);
          this.recibo = r;

          /**recibo de servicio */
          console.log('actualizar informacion de  recibo de servicio');
          this.itemsOtrosComplementos[0].id = this.recibo.id;
          this.itemsOtrosComplementos[0].fisicoLink = this.recibo.fotografia;
          this.itemsOtrosComplementos[0].estado = this.recibo.estado;
          this.itemsOtrosComplementos[0].original_estado = this.recibo.estado;
          this.itemsOtrosComplementos[0].ultima_actualizacion_usuario = this.recibo.ultima_modificacion_por_usuario_nombre;
          this.itemsOtrosComplementos[0].ultima_actualizacion_fecha = this.recibo.updatedAt;
          /** */
        });
      }

      if (
        this.detalleEvaluacion &&
        this.detalleEvaluacion.fiador_fotografia_de_casa_id
      ) {
        await this.getFotografiasDeCasa(
          this.detalleEvaluacion.fiador_fotografia_de_casa_id
        ).then((r) => {
          /** */
          console.log('current bd fotoCasa: ', r);
          this.fotoCasa = r;

          /**fotoCasa */
          console.log('actualizar informacion de  fotoCasa');
          this.itemsOtrosComplementos[1].id = this.fotoCasa.id;
          this.itemsOtrosComplementos[1].fisicoLink = this.fotoCasa.fotografia;
          this.itemsOtrosComplementos[1].estado = this.fotoCasa.estado;
          this.itemsOtrosComplementos[1].original_estado = this.fotoCasa.estado;
          this.itemsOtrosComplementos[1].ultima_actualizacion_usuario = this.fotoCasa.ultima_modificacion_por_usuario_nombre;
          this.itemsOtrosComplementos[1].ultima_actualizacion_fecha = this.fotoCasa.updatedAt;
          /** */
        });
      }

      /** */
      let vm = this;
      setTimeout(() => {
        vm.still_loading = false;
      }, 200);
    },
    //
    fn_check_if_pendiente_debe_actualizarse(complemento){
      console.log(complemento);
      this.findPendientes({
            query: {
              STATUS: 1,
              gestion_id: this.detalleEvaluacion.id,
              complemento_id: complemento.id,
              complemento_nombre: complemento.complemento_nombre
            }
          }).then(result => {
            // Verifica si existe algún pendiente
            if (result.data && result.data.length > 0) {
              const pendiente = result.data[0]; // Supongamos que solo necesitas el primero

              // Realiza el patch con el id del pendiente encontrado
              this.patchPendientes([pendiente.id, { STATUS: 2 }]);
            } else {
              console.error('No se encontró ningún pendiente, al aprobar el complemento');
            }
          }).catch(error => {
            console.error('Error al buscar el pendiente:', error);
          });
    },
    //
    /**integracion */
    /**implementacion */
    async fnDescargarContratoFotografia(ung_url, tipo) {
      const response = await fetch(ung_url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image_${tipo}.jpg`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    /**implementacion */
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    /*** */
    fnActualizarEstadoComplemento(complemento, index) {
      /** ACTUALIZACION DIRECTA A LA BASE DE DATOS POR ESTADOS APROBADO O PENDIENTE */
      if (complemento.estado == 'Aprobado') {
        const payload = {
          [complemento.tbl_attribute]: complemento.estado,
        };

        /** */
        let apiFunction;
        switch (complemento.API) {
          case 'patcDocumentosDeIdentificacion':
            apiFunction = this.patchDocumentosDeIdentificacion;
            break;
          case 'patcRecibos':
            apiFunction = this.patchRecibos;
            break;
          case 'patcFotografiasDeCasa':
            apiFunction = this.patchFotografiasDeCasa;
            break;
          case 'patchRecibosComerciales':
            apiFunction = this.patchRecibosComerciales;
            break;
          // Añade aquí más casos según sea necesario
          default:
            console.error('API desconocida:', complemento.API);
            return;
        }
        /** */

        apiFunction([complemento.id, payload]).then(() => {
          this.snackbar = true;
        });

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento);
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if  (complemento.estado == 'Rechazado') {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoRechazado = true;
        this.modalComplementoRechazadoData = complemento;
        this.modalComplementoRechazadoIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);

        /**si tiene pendiente, se actualiza a aprobado */
        this.fn_check_if_pendiente_debe_actualizarse(complemento);
        /**si tiene pendiente, se actualiza a aprobado, fin */

      } else if  (complemento.estado == 'Pendiente') {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoPendiente = true;
        this.modalComplementoPendienteData = complemento;
        this.modalComplementoPendienteIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }
    },
    async rechazarComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };
      /** */
      let apiFunction;
      switch (complemento.API) {
        case 'patcDocumentosDeIdentificacion':
          apiFunction = this.patchDocumentosDeIdentificacion;
          break;
        case 'patcRecibos':
          apiFunction = this.patchRecibos;
          break;
        case 'patcFotografiasDeCasa':
          apiFunction = this.patchFotografiasDeCasa;
          break;
        case 'patchRecibosComerciales':
          apiFunction = this.patchRecibosComerciales;
          break;
        // Añade aquí más casos según sea necesario
        default:
          console.error('API desconocida:', complemento.API);
          return;
      }
      /** */

      await apiFunction([complemento.id, payload]).then(async (r) => {
        console.log('contrato actualizado', r);
      });

      /** */
      let rectificacionTipo;
      switch (complemento.API) {
        case 'patcDocumentosDeIdentificacion':
          rectificacionTipo = 'documento de identificación';
          break;
        case 'patcRecibos':
          rectificacionTipo = 'recibo de servcio';
          break;
        case 'patcFotografiasDeCasa':
          rectificacionTipo = 'fotografía de casa';
          break;
        case 'patchRecibosComerciales':
          rectificacionTipo = 'recibo comercial';
          break;
        // Añade aquí más casos según sea necesario
        default:
          console.error('API desconocida, no se puede crear rectificacion');
          return;
      }
      /** */

      await this.createRectificaciones({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'fiador',
        rectificacion_tipo: rectificacionTipo,
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        rectificacion_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoRechazado = false;
        this.modalComplementoRechazadoData = null;
        this.comentario = null;
      });
    },
    async pendienteComplemento(complemento)  {
      const payload = {
        [complemento.tbl_attribute]: complemento.estado,
      };
      /** */
      let apiFunction;
      switch (complemento.API) {
        case 'patcDocumentosDeIdentificacion':
          apiFunction = this.patchDocumentosDeIdentificacion;
          break;
        case 'patcRecibos':
          apiFunction = this.patchRecibos;
          break;
        case 'patcFotografiasDeCasa':
          apiFunction = this.patchFotografiasDeCasa;
          break;
        case 'patchRecibosComerciales':
          apiFunction = this.patchRecibosComerciales;
          break;
        // Añade aquí más casos según sea necesario
        default:
          console.error('API desconocida:', complemento.API);
          return;
      }
      /** */

      await apiFunction([complemento.id, payload]).then(async (r) => {
        console.log('contrato actualizado', r);
      });

      /** */
      let rectificacionTipo;
      switch (complemento.API) {
        case 'patcDocumentosDeIdentificacion':
          rectificacionTipo = 'documento de identificación';
          break;
        case 'patcRecibos':
          rectificacionTipo = 'recibo de servcio';
          break;
        case 'patcFotografiasDeCasa':
          rectificacionTipo = 'fotografía de casa';
          break;
        case 'patchRecibosComerciales':
          rectificacionTipo = 'recibo comercial';
          break;
        // Añade aquí más casos según sea necesario
        default:
          console.error('API desconocida, no se puede crear rectificacion');
          return;
      }
      /** */

      await this.createPendientes({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'fiador',
        pendiente_tipo: rectificacionTipo,
        complemento_id: complemento.id,
        status_tbl_attribute: complemento.tbl_attribute,
        complemento_nombre: complemento.complemento_nombre,
        pendiente_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoPendiente = false;
        this.modalComplementoPendienteData = null;
        this.comentario = null;
      });
    },
    cancelar() {
      /**
       * reset estado a default value
       */
      if (
        this.modalComplementoRechazadoData && this.modalComplementoRechazadoData.API ==
        'patcDocumentosDeIdentificacion' 
        || this.modalComplementoPendienteData && this.modalComplementoPendienteData.API ==
        'patcDocumentosDeIdentificacion'
      ) {

        if (this.modalComplementoRechazadoData) {
          this.itemsDocumentoIdenfiticacion[
            this.modalComplementoRechazadoIndex
          ].estado = this.modalComplementoRechazadoData.original_estado;
        }

        if (this.modalComplementoPendienteData) {
          this.itemsDocumentoIdenfiticacion[this.modalComplementoPendienteIndex].estado =
          this.modalComplementoPendienteData.original_estado;  
        }
       
      } else {
        if (this.modalComplementoRechazadoData) {
          this.itemsOtrosComplementos[
            this.modalComplementoRechazadoIndex
          ].estado = this.modalComplementoRechazadoData.original_estado;  
        }

        if (this.modalComplementoPendienteData) {
          this.itemsOtrosComplementos[this.modalComplementoPendienteIndex].estado =
          this.modalComplementoPendienteData.original_estado;  
        }
        
      }

      /** */

      this.modalComplementoRechazado = false;
      this.modalComplementoRechazadoData = null;
      this.modalComplementoRechazadoIndex = null;

      /** */
      this.modalComplementoPendiente = false;
      this.modalComplementoPendienteData = null;
      this.modalComplementoPendienteIndex = null;
      /** */

      this.comentario = null;
    },
    // función para mostrar la fotografía dependiendo del botón presionado
    fnMostrarFotografia(item) {
      console.log('fotografía: ', item.title);
      this.infoComplementos = Object.assign(item);
      this.dialogDigital = true;
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en la respectiva ventana de dialogo que muestra las distintas fotografías
    fnDescargarFotografia() {
      //comprobamos
      console.log('descargando fotografía...', this.infoComplementos.title);
    },
  },
  watch: {},
  computed: {
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    },
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    comentarioValido() {
      return !!this.comentario;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
  },
  mounted() {
    let vm = this;
    setTimeout(() => {
      vm.main();
    }, 500);

/**actualizar datos al actualizar o crear compleentos de gestion */
const {
  Contratos,
  DocumentosDeIdentificacion,
  Recibos,
  FotografiasDeCasa,
  Referencias,
  RecibosComerciales
} = this.$FeathersVuex.api;
Contratos.on('patched', () => {
  this.main();
});
DocumentosDeIdentificacion.on('patched', () => {
  this.main();
});
Recibos.on('patched', () => {
  this.main();
});
FotografiasDeCasa.on('patched', () => {
  this.main();
});
Referencias.on('patched', () => {
  this.main();
});
RecibosComerciales.on('patched', () => {
  this.main();
});
/**actualizar datos al actualizar o crear compleentos de gestion, fin */
  },
};
</script>

<style></style>
