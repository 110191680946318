<template>
  <v-container fluid>

    <v-row>

      <v-col id="evaluacion" cols="12">
        <v-row>
          <v-col cols="9">
            <v-card>
              <v-card-title class="text-center justify-center">
                Evaluación
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Número de evaluación
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.id }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Estado de evaluación
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.estado }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Resultado de la evaluacion
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      <span :class="[
                        `${getColor(
                          detalleEvaluacion.resultado_evaluacion
                        )}--text`,
                      ]">{{ detalleEvaluacion.resultado_evaluacion || 'N/A' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Fecha evaluación
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ fnGetDateTime(detalleEvaluacion.createdAt) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Usuario móvil
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.CREADO_POR_USUARIO_NOMBRE }} (
                      {{ detalleEvaluacion.CREADO_POR_USUARIO }})
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Fecha campaña
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ fnGetDateUTC(detalleEvaluacion.fecha_campania) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      División
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.division }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Sector
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.sector }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Ruta
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.ruta }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--secondary font-weight-bold text-body-2">
                      Fue referida
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      <span v-if="
                        detalleEvaluacion.flag_persona_referida_por_consejera ==
                        1
                      ">
                        Si
                      </span>
                      <span v-else>No</span>
                    </td>
                  </tr>
                  <tr v-if="
                    detalleEvaluacion.flag_persona_referida_por_consejera == 1
                  ">
                    <td class="text--secondary font-weight-bold text-body-2">
                      Código de referida
                    </td>
                    <td class="text--secondary font-weight-regular text-body-2" align="left">
                      {{ detalleEvaluacion.codigo_referencia }}
                    </td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-img width="100%" alt="resultado evaluación"
              :src="imagenEstadoEvaluacion(detalleEvaluacion.resultado_evaluacion)" />
          </v-col>
        </v-row>

      </v-col>
      <v-col id="persona" cols="12">
        <v-card>
          <v-card-title class="text-center justify-center">
            Persona
          </v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Nombre de la persona
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.deudor_nombre_completo }}
                  </td>
                </tr>
                <tr v-if="buroInternoResult && buroInternoResult.apiResponse && buroInternoResult.apiResponse.codigo">
                  <td class="text--secondary font-weight-bold text-body-2">
                    Código en SAP
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ buroInternoResult?.apiResponse?.codigo }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Fecha de nacimiento
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ fnGetDateUTC(detalleEvaluacion.fecha_de_nacimiento) }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    DUI
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.dpi }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    DUI extendido en
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{
                      detalleEvaluacion.identificacion_extendida_en_departamento
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Número de identificación tributaria
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.nit }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Teléfono principal
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.numero_de_celular }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Teléfono secundario
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.telefono_de_casa }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Nacionalidad
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.nacionalidad }}
                  </td>
                </tr>


                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Número de contador nis
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.nocontador_nis }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Teléfono de trabajo
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.trabajo_telefono }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Tipo de vivienda
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.vivienda_tipo }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Tiempo de vivir en el domicilio
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{
                      tiempoResidenciaMeses(
                        detalleEvaluacion.cantidad_de_tiempo_en_vivienda_unidades,
                        detalleEvaluacion.cantidad_de_tiempo_en_vivienda_metrica
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Ubicación
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{
                      JSON.parse(detalleEvaluacion.vivienda_ubicacion).direccion
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Dirección
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.consejera_direccion }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Dirección de trabajo
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.trabajo_direccion }}
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary font-weight-bold text-body-2">
                    Dirección de entrega
                  </td>
                  <td class="text--secondary font-weight-regular text-body-2" align="left">
                    {{ detalleEvaluacion.entrega_direccion }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col id="flujo" cols="12">
        <!-- "Buro Interno" Steps -->
        <v-card class="mb-4 pa-4">
          <v-card-title class="text-center justify-center">
            Flujo de evaluación
          </v-card-title>
          <v-row class="mt-4" dense>
            <v-col v-for="(step, index) in evaluacion_flow" :key="index" cols="auto" class="d-flex align-center">
              <v-card class="px-3 py-2" :color="stepColor(step.color)" outlined>
                <div class="d-flex align-center">
                  <span>{{ step.label }}</span>
                  <v-icon v-if="step.color == 'green'" :color="step.color" class="ml-2">mdi-check-circle</v-icon>
                  <v-icon v-else-if="step.color == 'yellow'" :color="step.color" class="ml-2">mdi-alert-circle</v-icon>
                  <v-icon v-else-if="step.color == 'red'" :color="step.color"
                    class="ml-2">mdi-close-circle-outline</v-icon>
                  <v-icon v-else-if="step.color == 'none'" :color="'grey'" class="ml-2"></v-icon>
                  <v-icon v-else :color="step.color" class="ml-2">mdi-minus-circle-outline</v-icon>
                </div>
              </v-card>
              <v-icon v-if="index < evaluacion_flow.length - 1">mdi-chevron-right</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col id="flujo2" cols="12">
        <v-card>
          <v-card-title class="text-center justify-center">
            Flujo de evaluación
          </v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td><strong>Buró interno</strong></td>
                  <td>
                    Estado: <span :class="[
                      `${getColor(
                        buroInternoResult.finalResult.status
                      )}--text`,
                    ]"> {{
                      buroInternoResult?.finalResult?.status || 'N/A'
                    }}</span>
                    , Motivo: {{ buroInternoResult?.finalResult?.reason || '' }}
                  </td>
                </tr>
                <tr v-for="(detail, key) in buroInternoResult.details" :key="key">
                  <td>
                    <span style="font-size: 13px" class="font pl-2">
                      {{ key }}</span>
                  </td>
                  <td v-if="buroInternoResult?.finalResult?.status != 'NO EXISTE'">


                    <v-icon v-if="getColor(detail) == 'green'" :color="getColor(detail)"
                      class="pr-2">mdi-check-circle</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'yellow'" :color="getColor(detail)"
                      class="pr-2">mdi-alert-circle</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'red'" :color="getColor(detail)"
                      class="pr-2">mdi-close-circle-outline</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'none'" :color="'grey'" class="pr-2"></v-icon>
                    <v-icon v-else :color="getColor(detail)" class="pr-2">mdi-minus-circle-outline</v-icon>
                  </td>
                  <td v-if="buroInternoResult?.finalResult?.status == 'NO EXISTE'">
                    --
                  </td>
                </tr>
                <tr>
                  <td><strong>Políticas internas</strong></td>
                  <td>
                    Estado:
                    <span :class="[
                      `${getColor(
                        politicasInternasResult.finalResult.status
                      )}--text`,
                    ]">{{
                      politicasInternasResult?.finalResult?.status || 'N/A'
                    }}</span>


                    <span class="mr-1" v-for="(policy, index) in politicasInternasResult?.validations || []"
                      :key="index">
                      , <strong>{{ policy.rule }}</strong> - {{
                        policy.reason
                      }}

                    </span>
                  </td>
                </tr>
                <tr v-for="(detail, key) in politicasInternasResult.details" :key="key">
                  <td>
                    <span style="font-size: 12px" class="font pl-2">
                      {{ key }}</span>
                  </td>
                  <td>
                    <v-icon v-if="getColor(detail) == 'green'" :color="getColor(detail)"
                      class="pr-2">mdi-check-circle</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'yellow'" :color="getColor(detail)"
                      class="pr-2">mdi-alert-circle</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'red'" :color="getColor(detail)"
                      class="pr-2">mdi-close-circle-outline</v-icon>
                    <v-icon v-else-if="getColor(detail) == 'none'" :color="'grey'" class="pr-2"></v-icon>
                    <v-icon v-else :color="getColor(detail)" class="pr-2">mdi-minus-circle-outline</v-icon>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-size: 12px" class="font pl-2">
                      Tipo de cliente</span>
                  </td>
                  <td>
                    <strong>{{
                      detalleEvaluacion.buro_tipo_de_usuario
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><strong>EQUIFAX</strong></td>
                  <td>
                    Estado:
                    <span :class="[
                      `${getColor(
                        detalleEvaluacion.resultado_evaluacion_equifax
                      )}--text`,
                    ]">
                      {{
                        detalleEvaluacion.resultado_evaluacion_equifax || 'N/A'
                      }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col id="equifax" cols="12">
        <v-card>
          <v-card-title class="text-center justify-center">
            Informe de buró EQUIFAX
          </v-card-title>

          <v-card-text v-if="equifaxResult && equifaxResult.infoCrediticia && equifaxResult.infoCrediticia.ScoreActual">
            <gestionBuroEquifaxTablas :reporteURL="detalleEvaluacion.resultado_evaluacion_equifax_pdf_url"
              :detalleEvaluacion="detalleEvaluacion" :equifax_json="equifaxResult"
              :equifax_json_actual="equifaxResult.infoCrediticia.ScoreActual" :equifax_json_historico="equifaxResult.infoCrediticia.scoreHistorico
                "></gestionBuroEquifaxTablas>
          </v-card-text>
          <v-card-text v-else>
            <pre>{{ equifaxResult }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import gestionBuroEquifaxTablas from './gestion_buro_equifax_tablas.vue';
export default {
  name: 'vistaEvaluacion',
  props: ['detalleEvaluacion'],
  components: {
    gestionBuroEquifaxTablas,
  },
  data() {
    return {
      isBuroInternoDialogOpen: false,
      isEquifaxDialogOpen: false,
      //
      reingresoSelect: null, //variable para almacenar el valor del reingreso seleccionado
      //datos temporales con valores para el select de "Reingreso"
      itemsReingreso: ['reingreso', 'nuevo'],
    };
  },

  computed: {
    stepColor() {
      return (color) => {
        switch (color) {
          case 'green':
            return 'green lighten-4';
          case 'red':
            return 'red lighten-4';
          case 'yellow':
            return 'yellow lighten-4';
          default:
            return 'grey lighten-4';
        }
      };
    },
    evaluacion_flow() {

      /**inicio */
      let flow = [{ label: "Inicio", color: 'none' }];

      /**adjuntar buro interno */
      flow.push({ label: "Buró interno", color: this.getColor(this.buroInternoResult.finalResult.status) })

      /**adjuntar políticas internas */
      for (const [key, value] of Object.entries(this.politicasInternasResult.details)) {
        flow.push({
          label: 'politica: ' + key,
          color: this.getColor(value)
        });
      }

      /**adjuntar resultado equifax */
      flow.push({ label: "EQUIFAX 360", color: this.getColor(this.detalleEvaluacion.resultado_evaluacion_equifax) })

      /**fin */
      flow.push({ label: "Fin", color: 'none' })

      return flow;

    },
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true;
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true;
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true;
      } else {
        return false;
      }
    },
    buroInternoResult() {
      // Parse the JSON string from resultado_evaluacion_buro_interno
      try {
        return JSON.parse(
          this.detalleEvaluacion.resultado_evaluacion_buro_interno
        );
      } catch (e) {
        return {};
      }
    },
    politicasInternasResult() {
      // Parse the JSON string from resultado_evaluacion_politicas_internas
      try {
        return JSON.parse(
          this.detalleEvaluacion.resultado_evaluacion_politicas_internas
        );
      } catch (e) {
        return {};
      }
    },
    equifaxResult() {
      // Equifax data could be JSON or string, try parsing it
      let equifaxData =
        this.detalleEvaluacion.resultado_evaluacion_equifax_detalle;
      try {
        return JSON.parse(equifaxData);
      } catch (e) {
        return equifaxData;
      }
    },
    isEquifaxJson() {
      return typeof this.equifaxResult === 'object';
    },
    parsedEquifaxDetail() {
      return this.isEquifaxJson
        ? JSON.stringify(this.equifaxResult, null, 2)
        : this.equifaxResult;
    },
  },
  methods: {
    imagenEstadoEvaluacion(estado) {
      switch (estado) {
        case 'aprobado':
          return require('@/assets/images/aprobado.png');
        case 'revisión':
          return require('@/assets/images/revision.png');
        case 'rechazado':
          return require('@/assets/images/rechazado.png');
        default:
          return require('@/assets/images/rechazado.png');
      }
    },
    tiempoResidenciaMeses(tiempo, metrica) {
      if (metrica == 'Años') {
        return tiempo * 12 + ' Meses';
      }
      if (metrica == 'Días') {
        return Math.round(tiempo / 30) + ' Meses';
      }
      return tiempo + ' Meses';
    },
    fnActualizarTipoDeUsuario() {
      this.patchGestiones([
        this.detalleEvaluacion.id,
        {
          buro_tipo_de_usuario: this.detalleEvaluacion.buro_tipo_de_usuario,
        },
      ]).then(() => {
        this.$notify({
          title: 'Gestiones',
          text: 'Se ha actualizado el tipo de cliente.',
          color: '#4caf50',
          timeout: 3000,
        });
      });
    },
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    getColor(estado) {
      if (
        estado == 'rechazado' ||
        estado == 'RECHAZADO' ||
        estado == 'DENEGADO' ||
        estado == 'DENEGAR'
      ) {
        return 'red';
      } else if (
        estado == 'REVISIÓN' ||
        estado == 'revision' ||
        estado == 'revisión'
      ) {
        return 'yellow';
      } else if (
        estado == 'aprobado' ||
        estado == 'APROBADO' ||
        estado == 'APROBADO AUTOMATICO'
      ) {
        return 'green';
      } else {
        return 'grey';
      }
    },
    showBuroInternoDetails() {
      this.isBuroInternoDialogOpen = true;
    },
    showEquifaxDetails() {
      this.isEquifaxDialogOpen = true;
    },
  },
};
</script>
<style>
.my-masonry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(900px, 1fr));
  grid-gap: 1rem;
}
</style>
