import { render, staticRenderFns } from "./gestion_buro_fiador.vue?vue&type=template&id=5fb9ca64"
import script from "./gestion_buro_fiador.vue?vue&type=script&lang=js"
export * from "./gestion_buro_fiador.vue?vue&type=script&lang=js"
import style0 from "./gestion_buro_fiador.vue?vue&type=style&index=0&id=5fb9ca64&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports