<template>
  <div style="display: inline-block">
    <v-btn small @click="fnshowCancelarGestion()" color="red" text class="px-3 ml-4">
      {{ prop_btn_title }}
      <v-icon class="ml-2 mt-n1">mdi-cancel</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="790" persistent>
      <v-card>
        <v-card-title class="text-h5" v-if="!showCancelacion">
          ¿Está seguro de querer cancelar el proceso de esta gestión?
        </v-card-title>
        <v-card-title class="text-h5" v-if="showCancelacion">
          Gestión cancelada
        </v-card-title>

        <v-card-text>
          <p  v-if="!showCancelacion">Por favor indique el motivo de cancelación:</p>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="comentario"
              :rules="[rules.required]"
              label="Motivo de Cancelación"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-text v-if="showCancelacion">
          <v-alert
            :value="showCancelacion"
            type="error"
            shaped
            transition="fade-transition"
          >
            Gestión cancelada
            <loadingBar></loadingBar>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="cyan darken-1" :disabled="showCancelacion" text @click="dialog = false, comentario = null">
            Cancelar
          </v-btn>

          <v-btn
            :disabled="
              !comentario || comentario == '' || comentario.length <= 3 || showCancelacion
            "
            color="red darken-1"
            text
            @click="fhCancelarGestion()"
          >
            Cancelar gestión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loadingBar from './loading_bar.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  props: ['prop_gestion_detalle','prop_btn_title','prop_etapa'],
  components: {
    loadingBar,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      comentario: null,
      showCancelacion: false,
      dialog: false,
      valid: false,
    };
  },
  methods: {
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      patchGestiones: 'patch',
    }),
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    fnshowCancelarGestion() {
      this.dialog = true;
    },
    fhCancelarGestion() {
      this.$store.state.globalLoading = true;
      if (this.$refs.form.validate()) {
        this.patchGestiones([
          this.prop_gestion_detalle.id,
          {
            flag_precalificacion_cancelada: 1,
            flag_precalificacion_cancelada_fecha: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
            flag_precalificacion_cancelada_motivo: this.comentario,
            flag_precalificacion_cancelada_por_usuario: this.currentUser.nombre,
            flag_precalificacion_cancelada_por_usuario_id: this.currentUser.id,
            flag_precalificacion_cancelada_etapa: this.prop_etapa
          },
        ])
          .then(() => {
            this.$store.state.globalLoading = false;
            this.showCancelacion = true;

            setTimeout(() => {
              this.$router.push('/');
            }, 4000);
          })
          .catch((e) => {
            this.$store.state.globalLoading = false;
            this.$notify({
              title: 'Error',
              text: e,
              color: '#D32F2F',
              timeout: 3000,
            });
          });

        // setTimeout(() => {
        //   this.$store.state.globalLoading = true;
        // }, 350);
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
  },
};
</script>