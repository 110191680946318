<!-- integracion: borrar funcion fnRevisarPendientes-->
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-if="detalleEvaluacion.flag_aprobacion_con_compromiso_de_pago_y_rectificaciones_pendientes != 1">
            <v-btn x-small color="green" outlined class="px-4 mr-n2" elevation="1" @click="fnShowCompromisoDePago()">
              Aprobar compromiso(s) de pago
            </v-btn>
            <cancelar_gestion :prop_etapa="router_path_filtro" :prop_btn_title="'Denegar compromiso'"
              :prop_gestion_detalle="detalleEvaluacion"></cancelar_gestion>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="(item, i) in itemsCompromisosDePago" :key="i" cols="4">
                <v-card outlined>
                  <v-card-text class="text-center">
                    <!-- /**integracion */ -->
                    <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
                    <!-- /**integracion */ -->
                    <p class="text--primary font-weight-regular text-h6">
                      Compromiso de pago {{ item.id }}
                    </p>
                    <!-- <span v-if="!item.fisicoLink" size="52" class="mt-2">
                      No hay archivo</span> -->
                    <p>{{ item.comentario }}</p>
                    <p>
                      <small>Rectificacion ID: {{ item.rectificacion.id }}</small><br>
                      <small>Rectificación comentario: {{ item.rectificacion.rectificacion_comentario }}</small>
                    </p>
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row align="center" justify="center">
                      <v-col cols="12">
                        <v-btn v-if="item.fisicoLink" x-small color="grey" outlined class="px-4 mr-n2" elevation="1"
                          @click="fnCompromisoFisico(item)">
                          Físico
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="justify-space-around pb-6 mt-n2">

                    <!-- <v-btn :disabled="item.STATUS != 1" x-small color="green" outlined class="px-4 mr-n2" elevation="1"
              @click="fnShowCompromisoDePago(item)">
              Aprobar compromiso
            </v-btn>

            <cancelar_gestion v-if="item.STATUS == 1" :prop_etapa="router_path_filtro"
              :prop_btn_title="'Denegar compromiso'" :prop_gestion_detalle="detalleEvaluacion"></cancelar_gestion> -->
                  </v-card-actions>

                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <p class="text-h6 font-weight-regular mt-2">Revisión contrato cargado</p>

    <!-- ventana de dialogo para mostrar la fotografía del contrato físico -->
    <v-dialog v-model="dialogFisico" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Fotografía contrato parte {{ infoCompromiso.parte }}</v-card-title>

        <v-card-text>
          <v-img aspect-ratio="1.4" contain transition="scale-transition" alt="gotografía contrato"
            :src="VUE_APP_FEATHERS_SERVER + infoCompromiso.fisicoLink" />
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small @click="
            fnDescargarContratoFotografia(
              VUE_APP_FEATHERS_SERVER + infoCompromiso.fisicoLink,
              `Fotografía contrato parte ${infoCompromiso.parte}`
            )
            ">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogFisico = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana de dialogo para mostrar el contrato digital -->
    <v-dialog v-model="dialogDigital" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Contrato digital parte {{ infoCompromiso.parte }}</v-card-title>
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img alt="contrato digital" class="shrink" contain src="@/assets/images/contrato.jpg" width="300" />
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn color="#031D6A" class="px-5 white--text mr-4" small
            @click="fnDescargarContratoDigital(infoCompromiso)">
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogDigital = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-dialog v-model="modalComplementoRechazado" max-width="500">
      <v-card v-if="modalComplementoRechazadoData">
        <v-card-title class="headline">Rechazar complemento</v-card-title>
        <v-card-text>
          <p>
            ¿Está seguro de que desea rechazar el complemento
            <strong>{{
              modalComplementoRechazadoData.complemento_nombre
            }}</strong>?
          </p>
          <small><strong>Advertencia</strong></small>
          <p>
            *. Se enviará una notificación a la supervisora de que se ha enviado
            un complemento para rectificación.
          </p>
          <p>
            *. Esta rectificación desaparecerá hasta que sea corregida por la
            supervisora.
          </p>
          <v-text-field ref="motivorechazocomentario" v-model="comentario" label="Motivo del rechazo" required
            :rules="[rules.required]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="rechazarComplemento(modalComplementoRechazadoData)"
            :disabled="!comentarioValido">Sí, enviar a rectificación</v-btn>
          <v-btn color="grey" @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <!-- /** */ -->
    <v-dialog v-model="modaAprobarCompromisodePago" max-width="500">
      <v-card>
        <v-card-title class="headline">Aprobar compromiso de pago</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="fnAprobarCompromisosDePago()">Sí</v-btn>
          <v-btn color="grey" @click="modaAprobarCompromisodePago = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /** */ -->
    <v-snackbar v-model="snackbar" timeout="3500" color="success" right top class="mt-14">
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Estado de complemento actualizado
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- /**LOADING BAR */ -->
    </v-snackbar>
  </v-container>
</template>

<script>
import cancelar_gestion from './../../cancelar_gestion.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'vistaContrato',
  props: ['detalleEvaluacion'],
  components: {
    cancelar_gestion,
  },
  data() {
    return {
      snackbar: false,
      /**por rechazo de complemento */
      modalComplementoRechazado: false,
      modalComplementoRechazadoData: null,
      modaAprobarCompromisodePago: false,
      modaAprobarCompromisodePagoData: null,
      still_loading: true,
      comentario: '',
      rules: {
        required: (v) => !!v || 'El comentario es requerido',
      },
      /**integracion */
      contrato: null,
      /**integracion */
      //variables para las ventánas de dialogo que muestran las ventanas de los contratos
      dialogFisico: false,
      dialogDigital: false,

      //items temporales para las cards de el apartado de CONTRATO
      itemsCompromisosDePago: [],

      infoCompromiso: {}, //variable para mostrar la parte del contrato en la ventana de diálogo cuando se da clic en los respectivos botones

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      itemsEstado: ['Aprobado', 'Rechazado', 'Pendiente'],
    };
  },
  computed: {
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    comentarioValido() {
      return !!this.comentario;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
  },
  methods: {
    ...mapActions('Contratos', {
      findContratos: 'find',
      getContratos: 'get',
      patchContratos: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      createRectificaciones: 'create',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('CompromisosDePago', {
      findCompromisosDePago: 'find',
      patchCompromisosDePago: 'patch',
    }),
    fnShowCompromisoDePago() {
      this.modaAprobarCompromisodePago = true;
    },
    //
    async main() {
      /**integracion */

      console.log('hay nuevo detalleEvaluacion - contrato side');

      if (this.detalleEvaluacion && this.detalleEvaluacion.contrato_id) {
        /**obtener informacion de CONTRATO
         * Y SIGNARLO A itemsCompromisosDePago
         */
        await this.findCompromisosDePago({
          query: {
            perfil_de_usuario: 'fiador',
            gestion_id: this.detalleEvaluacion.id,
            //STATUS: 1,
            $limit: 100,
            $sort: {
              id: -1,
            },
          },
        }).then((r) => {
          console.log('compromisos de pago: ', r);

          for (let index = 0; index < r.data.length; index++) {
            r.data[index].original_estado = r.data[index].STATUS;
            r.data[index].fisicoLink = r.data[index].fotografia;
          }
          this.itemsCompromisosDePago = r.data;
          /** */
          let vm = this;
          setTimeout(() => {
            vm.still_loading = false;
          }, 500);
        });
      }
      /**integracion */
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },

    /*** */
    fnActualizarEstadoComplemento(complemento, index) {
      /** ACTUALIZACION DIRECTA A LA BASE DE DATOS POR ESTADOS APROBADO O PENDIENTE */
      if (complemento.STATUS != 'Rechazado') {
        const payload = {
          [complemento.tbl_attribute]: complemento.STATUS,
        };

        this.patchContratos([this.contrato.id, payload]).then(() => {
          this.snackbar = true;
        });
      } else {
        /**VERIFICACION DE ACTUALIZACION POR ESTADO RECHAZADO */
        this.modalComplementoRechazado = true;
        this.modalComplementoRechazadoData = complemento;
        this.modalComplementoRechazadoIndex = index;
        let vm = this;
        setTimeout(() => {
          vm.$refs.motivorechazocomentario.focus();
        }, 150);
      }
    },
    async rechazarComplemento(complemento) {
      const payload = {
        [complemento.tbl_attribute]: complemento.STATUS,
      };

      await this.patchContratos([this.contrato.id, payload]).then(async (r) => {
        console.log('contrato actualizado', r);
      });

      await this.createRectificaciones({
        gestion_id: this.detalleEvaluacion.id,
        perfil_de_usuario: 'fiador',
        rectificacion_tipo: 'compromiso de pago',
        complemento_id: complemento.id,
        complemento_nombre: complemento.tbl_attribute,
        rectificacion_comentario: this.comentario,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then(() => {
        this.snackbar = true;
        this.modalComplementoRechazado = false;
        this.modalComplementoRechazadoData = null;
        this.comentario = null;
      });
    },
    cancelar() {
      /**
       * reset estado a default value
       */
      this.itemsCompromisosDePago[this.modalComplementoRechazadoIndex].STATUS =
        this.modalComplementoRechazadoData.original_estado;
      /** */
      this.modalComplementoRechazado = false;
      this.modalComplementoRechazadoData = null;
      this.modalComplementoRechazadoIndex = null;
      this.comentario = null;
    },
    //-----funciones cuando se da clic en los botones de FÍSICO - DIGITAL en las cards del apartado de CONTRATO

    //clic en botón FÍSICO
    fnCompromisoFisico(item) {
      this.infoCompromiso = Object.assign(item);
      console.log('se dio clic en el botón', this.infoCompromiso); // comprobando que si se da clic sobre items diferentes dependiendo de en que card esté el botón
      this.dialogFisico = true;
    },

    //clic en botón DIGITAL
    fnContratoDigital(item) {
      this.infoCompromiso = Object.assign(item);
      console.log('se dio clic en el botón', this.infoCompromiso);
      this.dialogDigital = true;
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en las respectivas ventanas de dialogo, fotografía de contrato y contrato digital
    /**implementacion */
    async fnDescargarContratoFotografia(ung_url, tipo) {
      const response = await fetch(ung_url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `image_${tipo}.jpg`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    /**implementacion */
    fnDescargarContratoDigital(data) {
      console.log('descargando digital...', data.digitalLink);
      console.log(this.infoCompromiso.digitalLink);
    },
    /** */
    fnAprobarCompromisosDePago() {
      for (let index = 0; index < this.itemsCompromisosDePago.length; index++) {
        const compromiso = this.itemsCompromisosDePago[index];
        this.fnAprobarCompromiso(compromiso);
      }

    },
    async fnAprobarCompromiso(compromisito) {
      await this.patchCompromisosDePago([compromisito.id, { STATUS: 2 }]).then(
        (r) => {
          console.log('se aprobó el compromiso de pago', r);

          if (r.flag_hay_mas_compromisos != 1) {

            this.$notify({
              title: 'Aprobado',
              text: 'se aprobó el compromiso de pago',
              color: '#2E7D32',
              timeout: 3000,
            });

            this.modaAprobarCompromisodePago = false;
          }
        }
      );
    },
  },
  watch: {},

  //para verificar si se tiene algun pendiente (únicamente para pruebas)
  mounted() {
    let vm = this;
    setTimeout(() => {
      vm.main();
    }, 500);
  },
};
</script>

<style scoped>
.v-select .v-input--dense .v-select__selection--comma {
  margin: 0px 0px 0px 20px;
}
</style>
s