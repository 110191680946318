<template>
    <v-container>
      <v-progress-linear
        :value="progress"
        :buffer-value="progress"
        height="6"
        color="white"
      ></v-progress-linear>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        progress: 0,
      };
    },
    mounted() {
      this.startLoading();
    },
    methods: {
      startLoading() {
        const interval = 30; // Update progress every 30ms
        const step = 100 / (3000 / interval); // Calculate the step size
        const loading = setInterval(() => {
          if (this.progress >= 100) {
            clearInterval(loading);
          } else {
            this.progress += step;
          }
        }, interval);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any styles you need here */
  </style>
  